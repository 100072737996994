<template>
  <div id="roles">
    <AppContent>
      <PageHeader title="Roles">
        <v-btn color="primary" @click="editRole(defaultModel())">
          <v-icon class="mr-2">mdi-plus</v-icon>
          Add Role
        </v-btn>
      </PageHeader>

      <v-data-table
          :headers="headers"
          :items="roles"
          :options.sync="options"
          :server-items-length="total"
          :loading="loading"
          :footer-props="{ 'items-per-page-options': [5, 10, 25, 50, 100] }"
          sort-by="name"
      >
        <template #item.permissions="{ item }">
          {{item.permissions.map((permId) => permissionName(permId)).join(', ')}}
        </template>

        <template #item.edit="{ item }">
          <v-btn icon color="warning" @click="editRole(item)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>

        <template #item.delete="{ item }">
          <v-btn icon color="error" @click="deleteRole(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>

      <RoleEditDialog
          ref="roleEditDialog"
          :role="role"
          :permissions="permissions"
          @save="refresh()"
      ></RoleEditDialog>
    </AppContent>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { NoahTextAPI } from '@/common/noahtext-api';
import { DEFAULT_ROLE } from '@/common/util';
import AppContent from '@/components/general/AppContent';
import RoleEditDialog from '@/components/roles/RoleEditDialog';
import PageHeader from '@/components/general/PageHeader';

export default {
  name: 'Roles',
  components: {AppContent, RoleEditDialog, PageHeader},
  data: () => ({
    loading: false,
    roles: [],
    permissions: [],
    role: DEFAULT_ROLE(),
    defaultModel: DEFAULT_ROLE,
    total: 0,
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Permissions', value: 'permissions', sortable: false },
      { text: 'Edit', value: 'edit', sortable: false },
      { text: 'Delete', value: 'delete', sortable: false }
    ],
    options: {}
  }),
  async mounted() {
    this.permissions = (await NoahTextAPI.get('permissions', {
      params: {
        limit: -1
      }
    })).results;
  },
  watch: {
    options() {
      this.refresh();
    }
  },
  methods: {
    async refresh() {
      let { sortBy, sortDesc, page, itemsPerPage } = this.options;

      this.loading = true;
      const response = await NoahTextAPI.get('roles', {
        params: {
          offset: (page - 1) * itemsPerPage,
          limit: itemsPerPage,
          order: sortBy.length > 0 ? `${sortDesc[0] ? '-' : ''}${sortBy[0]}` : undefined
        }
      });

      this.total = response.count;
      this.roles = response.results;
      this.loading = false;
    },
    permissionName(permissionId) {
      const results = this.permissions.filter((p) => p.id === permissionId);
      if (results.length > 0) {
        return results[0].name;
      } else {
        return 'Unknown';
      }
    },
    editRole(role) {
      this.role = cloneDeep(role);
      this.$nextTick(() => this.$refs.roleEditDialog.open());
    },
    async deleteRole(role) {
      if (confirm('Are you sure you want to delete this role?')) {
        await NoahTextAPI.deleteRole(role);
        await this.refresh();
      }
    },
  }
}
</script>

<style scoped>

</style>