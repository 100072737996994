<template>
  <v-dialog
    :fullscreen="$vuetify.breakpoint.smAndDown"
    v-model="dialog"
    max-width="800"
    persistent
  >
    <v-card>
      <v-toolbar dark color="secondary">
        <v-toolbar-title>{{title}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <slot name="title"></slot>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <template v-if="!!$slots.extension" #extension>
          <slot name="extension"></slot>
        </template>
      </v-toolbar>

      <div class="content" :style="{ padding: `${padding}px` }">
        <slot></slot>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Dialog",
  props: {
    title: {
      type: String,
      default: 'Dialog'
    },
    padding: {
      type: Number,
      default: 16
    }
  },
  data: () => ({
    dialog: false
  }),
  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    }
  }
}
</script>

<style scoped>
.content {
  overflow-x: auto;
}
</style>