import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import Snackbar from '@/plugins/snackbar';
import Vuetify from 'vuetify';
import VueGtag from 'vue-gtag';
import { TiptapVuetifyPlugin } from 'tiptap-vuetify';

Vue.config.productionTip = false;

Vue.use(Vuetify);
Vue.use(TiptapVuetifyPlugin, { vuetify, iconsGroup: 'mdi' });
Vue.use(Snackbar);
Vue.use(VueGtag, {
  config: {
    id: 'G-JL0MM1X281',
    params: {
      cookie_flags: 'SameSite=None;Secure;'
    }
  }
}, router);

export default new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app');
