<template>
  <div id="analytics">
    <v-tabs v-model="tab">
      <v-tab href="#logins">Logins</v-tab>
      <v-tab href="#conversions">Conversions</v-tab>
      <v-tab href="#documents">Documents</v-tab>
      <v-tab href="#words">Words</v-tab>
    </v-tabs>

    <AppContent>
      <v-tabs-items :value="tab">
        <v-tab-item value="logins">
          <AnalyticsLogins></AnalyticsLogins>
        </v-tab-item>
        <v-tab-item value="conversions">
          <AnalyticsConversions></AnalyticsConversions>
        </v-tab-item>
        <v-tab-item value="documents">
          <AnalyticsDocConversions></AnalyticsDocConversions>
        </v-tab-item>
        <v-tab-item value="words">
          <AnalyticsWordEdits></AnalyticsWordEdits>
        </v-tab-item>
      </v-tabs-items>
    </AppContent>
  </div>
</template>

<script>
import AnalyticsLogins from '@/views/admin/AnalyticsLogins';
import AnalyticsConversions from '@/views/admin/AnalyticsConversions';
import AppContent from '@/components/general/AppContent';
import AnalyticsDocConversions from '@/views/admin/AnalyticsDocConversions';
import AnalyticsWordEdits from '@/views/admin/AnalyticsWordEdits';

export default {
  name: 'Analytics',
  components: {AnalyticsWordEdits, AnalyticsDocConversions, AnalyticsConversions, AnalyticsLogins, AppContent},
  computed: {
    tab: {
      set (tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } })
      },
      get () {
        return this.$route.query.tab
      }
    }
  }
}
</script>

<style scoped>

</style>