<template>
  <v-footer app color="primary" padless dark absolute>
    <v-row
      justify="center"
      no-gutters
    >
      <v-col class="text-center py-2" cols="12">
        <v-btn :to="{ name: 'terms-of-use' }" text small rounded>
          Terms of Use
        </v-btn>
        <v-btn :to="{ name: 'privacy-policy' }" text small rounded>
          Privacy Policy
        </v-btn>
        <v-btn text small rounded @click="$refs.soundKeyDialog.open()">
          How It Works
        </v-btn>
        <v-btn
            href="https://www.noahtext.com/"
            target="_blank"
            text
            small
            rounded
        >
          About
        </v-btn>
      </v-col>
      <v-col class="text-center primary lighten-1 py-2" cols="12">
        &copy; {{ new Date().getFullYear() }} <b>Noah Text, LLC</b>
      </v-col>
    </v-row>

    <SoundKey ref="soundKeyDialog"></SoundKey>
  </v-footer>
</template>

<script>
import SoundKey from '@/components/general/SoundKey';

export default {
  name: 'AppFooter',
  components: {SoundKey},
}
</script>

<style scoped>

</style>