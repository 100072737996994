<template>
  <div id="dictionary">
    <AppContent>
      <PageHeader title="Dictionary">
        <v-btn color="primary" @click="$refs.wordTable.addWord()">
          <v-icon class="mr-2">mdi-plus</v-icon>
          Add Word
        </v-btn>
      </PageHeader>

      <WordTable ref="wordTable"></WordTable>
    </AppContent>
  </div>
</template>

<script>
import AppContent from '@/components/general/AppContent';
import PageHeader from '@/components/general/PageHeader';
import WordTable from '@/components/words/WordTable';

export default {
  name: 'Dictionary',
  components: {AppContent, PageHeader, WordTable }
}
</script>

<style scoped>

</style>
