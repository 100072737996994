<template>
  <div id="review">
    <v-select
        class="mb-2"
        label="Show Words"
        filled
        hide-details
        @input="updateValidity"
        :items="validFilters"
        v-model="validFilter"
    ></v-select>

    <div :class="{ 'd-flex': $vuetify.breakpoint.mdAndUp }">
      <v-select
          :class="{ 'flex-grow-0': true, 'mb-2': true, 'mr-2': $vuetify.breakpoint.mdAndUp }"
          label="Search Comparison"
          filled
          hide-details
          @input="updateComparison"
          :items="searchComps"
          v-model="searchComp"
      ></v-select>

      <v-text-field
          class="flex-grow-1"
          label="Search Dictionary"
          hide-details
          clearable
          filled
          v-model="search"
      ></v-text-field>
    </div>

    <v-data-table
        :headers="headers"
        :items="words"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        :item-class="rowClass"
        :footer-props="{ 'items-per-page-options': [5, 10, 25, 50, 100] }"
        :sort-by="review ? 'hits' : 'content'"
        :sort-desc="review"
    >
      <template #item.content="{ item }">
        <span class="word">
          <b>
            {{item.content ? item.content[0].toUpperCase() + item.content.slice(1) : ''}}
          </b>
        </span>
      </template>

      <template #item.syllables="{ item }">
        <span class="word" v-html="syllables(item)"></span>
      </template>

      <template #item.longVowels="{ item }">
        <span class="word" v-html="longVowels(item)"></span>
      </template>

      <template #item.partOfSpeech="{ item }">
        {{partsOfSpeech[item.partOfSpeech]}}
      </template>

      <template #item.edit="{ item }">
        <v-btn icon color="warning" @click="editWord(item)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>

      <template #item.review="{ item }">
        <v-btn icon color="success" @click="reviewWord(item)">
          <v-icon>mdi-clipboard-search-outline</v-icon>
        </v-btn>
      </template>

      <template #item.valid="{ item }">
        <v-checkbox
            class="ma-0 pa-0"
            hide-details
            v-model="item.valid"
            @change="validifyWord(item)"
        ></v-checkbox>
      </template>
    </v-data-table>

    <WordEditDialog
        ref="wordEditDialog"
        :initial-word="word"
        @save="refresh()"
        @delete="refresh()"
    />

    <Dialog ref="addWordDialog" title="Add Word">
      <v-text-field
          filled
          hide-details
          label="New Word"
          v-model="addWordContent"
          autofocus
      ></v-text-field>

      <v-divider class="my-4"></v-divider>

      <div class="d-flex">
        <v-spacer></v-spacer>

        <v-btn text @click="$refs.addWordDialog.close()">
          Cancel
        </v-btn>

        <v-btn
            text
            color="primary"
            @click="finishAddWord"
        >
          Add
        </v-btn>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import { NoahTextAPI } from '@/common/noahtext-api';
import { PARTS_OF_SPEECH, DEFAULT_WORD, SEARCH_COMPARISONS } from '@/common/util';
import Dialog from '@/components/general/Dialog';
import WordEditDialog from '@/components/words/WordEditDialog';

export default {
  name: 'WordTable',
  components: { Dialog, WordEditDialog },
  props: {
    review: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    words: [],
    word: DEFAULT_WORD(),
    defaultModel: DEFAULT_WORD,
    addWordContent: '',
    loading: true,
    total: 0,
    options: {},
    searchComp: SEARCH_COMPARISONS[0].value,
    searchComps: SEARCH_COMPARISONS,
    search: null,
    searchTimeout: null,
    validFilters: [
      { text: 'All Words', value: null },
      { text: 'Valid Only', value: true },
      { text: 'Invalid Only', value: false }
    ],
    validFilter: true
  }),
  created() {
    if (localStorage.searchComp) {
      const comp = JSON.parse(localStorage.searchComp);
      this.searchComp = this.searchComps.filter((c) => c.value.key === comp)[0].value;
    }

    if (localStorage.validFilter) {
      this.validFilter = JSON.parse(localStorage.validFilter);
    }
  },
  watch: {
    options: {
      handler() {

        this.refresh();
      },
      deep: true
    },
    search() {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(
          () => this.refresh(true),
          500
      );
    }
  },
  computed: {
    ...mapGetters(['guard']),
    headers() {
      const headers = [
        { text: 'Word', value: 'content' },
        { text: 'Part of Speech', value: 'partOfSpeech' },
        { text: 'Long Vowels', value: 'longVowels', sortable: false },
        { text: 'Syllables', value: 'syllables', sortable: false }
      ];

      if (this.guard(['nt:ManageWords'])) {
        headers.push({ text: 'Hits', value: 'hits' });
        headers.push({ text: 'In Dictionary', value: 'valid', sortable: false });
        if (this.review) {
          headers.push({ text: 'Mark Reviewed', value: 'review', sortable: false });
        }
        headers.push({ text: 'Edit', value: 'edit', sortable: false });
      }

      return headers;
    },
    partsOfSpeech() {
      const parts = {};
      PARTS_OF_SPEECH.forEach((part) => {
        parts[part.value] = part.text;
      });
      return parts;
    }
  },
  methods: {
    addWord() {
      this.$refs.addWordDialog.open();
    },
    finishAddWord() {
      this.$refs.addWordDialog.close();
      this.word = {
        ...DEFAULT_WORD(),
        content: this.addWordContent.toLowerCase(),
        syllables: [this.addWordContent.length]
      };
      this.addWordContent = '';
      this.$nextTick(() => this.$refs.wordEditDialog.open());
    },
    updateComparison() {
      localStorage.searchComp = JSON.stringify(this.searchComp.key);
      this.refresh(true);
    },
    updateValidity() {
      localStorage.validFilter = JSON.stringify(this.validFilter);
      this.refresh(true);
    },
    async refresh(reset = false) {
      let { sortBy, sortDesc, page, itemsPerPage } = this.options;
      if (reset) { page = 1; }

      this.loading = true;

      const search = this.search?.trim();
      const response = await NoahTextAPI.get('words', {
        params: {
          offset: (page - 1) * itemsPerPage,
          limit: itemsPerPage,
          order: sortBy.length > 0 ? `${sortDesc[0] ? '-' : ''}${sortBy[0]}` : undefined,
          content: search ? this.searchComp.func(search.toLowerCase()) : undefined,
          review: this.review ? 'not({})' : undefined,
          valid: this.validFilter !== null ? this.validFilter : undefined
        }
      });

      this.total = response.count;
      this.words = response.results;
      this.loading = false;
    },
    longVowels(word) {
      const data = word.content.split('');
      for (const long of word.longVowels) {
        data[long] = `<b><u>${data[long]}</u></b>`;
      }
      return data.join('');
    },
    syllables(word) {
      const parts = [];
      let cur = 0;
      for (const end of word.syllables) {
        parts.push(word.content.slice(cur, end));
        cur = end;
      }
      return parts.join('<b>-</b>');
    },
    rowClass(word) {
      if (word.review.length > 0) {
        return 'warning lighten-5';
      }
    },
    editWord(word) {
      this.word = cloneDeep(word);
      this.$nextTick(() => this.$refs.wordEditDialog.open());
    },
    async deleteWord(word) {
      if (confirm('Are you sure you want to delete this word?')) {
        await NoahTextAPI.deleteWord(word);
        await this.refresh();
      }
    },
    async reviewWord(word) {
      await NoahTextAPI.updateWord({
        ...word,
        review: []
      });
      await this.refresh();
    },
    async validifyWord(word) {
      await NoahTextAPI.updateWord({
        ...word,
        valid: word.valid
      });
      await this.refresh();
    }
  }
}
</script>

<style scoped>
.word {
  font: 14pt "Courier New", monospace;
}
</style>
