<template>
  <div id="login">
    <AppContent>
      <v-card class="mx-auto" max-width="400">
        <v-toolbar dark color="secondary">
          <v-toolbar-title>Log In</v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <v-form v-model="valid" @submit.prevent="login">
            <v-alert v-if="error" type="error" text dense border="left">
              {{error}}
            </v-alert>

            <v-text-field
                v-model="email"
                :rules="[v => !!v.trim() || 'Email is required']"
                autofocus
                filled
                label="Email"
            ></v-text-field>

            <v-text-field
                v-model="password"
                :rules="[v => !!v.trim() || 'Password is required']"
                type="password"
                filled
                label="Password"
            ></v-text-field>

            <v-btn
                class="mb-2"
                :disabled="!valid"
                :loading="loading"
                type="submit"
                block
                color="primary"
            >
              Log In
            </v-btn>

            <div>
              <router-link :to="{ name: 'forgot-password' }">Forgot Password</router-link>
            </div>

            <v-divider class="my-4"></v-divider>

            <div class="text-center">
              <router-link :to="{ name: 'signup' }">Sign up for a <b>free</b> account</router-link>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </AppContent>
  </div>
</template>

<script>
import { NoahTextAPI } from '@/common/noahtext-api';
import { M_SET_TOKEN, M_SET_USER } from '@/store/mutations.type';
import AppContent from '@/components/general/AppContent';

export default {
  name: 'Login',
  components: {AppContent},
  data: () => ({
    valid: false,
    email: '',
    password: '',
    loading: false,
    error: ''
  }),
  methods: {
    async login() {
      if (this.loading) return;

      this.error = '';
      this.loading = true;
      if (this.valid) {
        try {
          const response = await NoahTextAPI.login({
            email: this.email,
            password: this.password
          });

          this.$store.commit(M_SET_TOKEN, response.token);
          this.$store.commit(M_SET_USER, response.user);
          await this.$router.replace({ name: 'convert' });
        } catch (e) {
          this.error = e;
        }
      }
      this.loading = false;
    }
  }
}
</script>

<style scoped>

</style>
