import { M_SET_LOADING, M_SET_TOKEN, M_SET_USER } from '@/store/mutations.type';
import { base64UrlDecode } from '@/common/util';

const state = {
  token: null,
  user: {},
  permissions: [],
  loading: true
};

const getters = {
  user: state => state.user,
  authed: state => !!state.token,
  token: state => state.token,
  guard: state => perms => {
    // Convert to array
    if (typeof perms === 'string') {
      perms = [perms];
    }

    // Special case - guard([]) verifies that no user is logged in
    if (perms.length === 0) {
      return !state.token;
    }

    // If not logged in, fail
    if (!state.token) {
      return false;
    }

    // Admins override everything
    if (state.permissions.includes('nt:Administrator')) {
      return true;
    }

    // Do we have the correct permissions?
    for (const perm of perms) {
      if (!state.permissions.includes(perm)) return false;
    }
    return true;
  },
  resetPassword: state => state.permissions && state.permissions.includes('nt:ResetPassword'),
  showNav: (state, getters) => getters.authed && !getters.resetPassword
};

const actions = {

};

const mutations = {
  [M_SET_TOKEN](state, token) {
    state.token = token;
    if (token) {
      const parts = token.split('.');
      const { id, permissions } = JSON.parse(base64UrlDecode(parts[1]));
      state.user = { id };
      state.permissions = permissions;
      localStorage.token = JSON.stringify(token);
    } else {
      state.user = {};
      state.permissions = [];
      localStorage.removeItem('token');
    }
  },
  [M_SET_USER](state, user) {
    state.user = user;
  },
  [M_SET_LOADING](state, loading) {
    state.loading = loading;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
