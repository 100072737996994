<template>
  <Dialog ref="dialog" :title="`${user.id ? 'Edit' : 'New'} User`">
    <v-form @submit.prevent="save" v-model="valid">
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
              autofocus
              label="First Name"
              filled
              v-model="user.firstName"
              :rules="[v => !!v.trim() || 'Last Name is required']"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
              label="Last Name"
              filled
              v-model="user.lastName"
              :rules="[v => !!v.trim() || 'Last Name is required']"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-text-field
          label="Email"
          filled
          v-model="user.email"
          :rules="[v => !!emailRegex.exec(v) || 'Invalid email address']"
      ></v-text-field>

      <v-row>
        <v-col cols="12" sm="6">
          <v-select
              v-model="professionSelection"
              filled
              :items="professions"
              label="Profession (Optional)"
              :hide-details="professionSelection.startsWith('Other')"
              clearable
          ></v-select>
          <v-text-field
              v-if="professionSelection.startsWith('Other')"
              v-model="user.profession"
              filled
              label="Profession"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
              label="Business Name"
              filled
              v-model="user.business"
          ></v-text-field>
        </v-col>
      </v-row>

      <div class="d-flex align-center">
        <v-select
            :items="roles"
            filled
            label="Roles"
            item-text="name"
            item-value="id"
            v-model="role"
            hide-details
        ></v-select>
        <v-btn class="ml-2" color="primary" @click="addRole">
          <v-icon>mdi-plus</v-icon>
          Add
        </v-btn>
      </div>

      <v-list-item v-for="(roleId, idx) in user.roles" :key="idx">
        <v-list-item-title>{{roleName(roleId)}}</v-list-item-title>

        <v-list-item-action>
          <v-btn icon @click="user.roles.splice(idx, 1)">
            <v-icon color="error">mdi-delete</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-form>

    <v-divider class="my-4"></v-divider>

    <div class="d-flex mt-2">
      <v-spacer></v-spacer>

      <v-btn text @click="$refs.dialog.close()">
        Cancel
      </v-btn>

      <v-btn
          text
          color="primary"
          :loading="saving"
          @click="save"
      >
        Save
      </v-btn>
    </div>
  </Dialog>
</template>

<script>
import { NoahTextAPI } from '@/common/noahtext-api';
import { EMAIL_REGEX, PROFESSIONS } from '@/common/util';
import Dialog from '@/components/general/Dialog';

export default {
  name: "UserEditDialog",
  components: { Dialog },
  props: {
    user: {
      type: Object,
      required: true
    },
    roles: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    saving: false,
    valid: false,
    emailRegex: EMAIL_REGEX,
    role: null,
    professions: PROFESSIONS,
    professionSelection: ''
  }),
  watch: {
    professionSelection() {
      if (this.professionSelection === null) {
        this.professionSelection = '';
      } else if (this.professionSelection.startsWith('Other')) {
        if (this.professions.includes(this.user.profession)) {
          this.user.profession = '';
        }
      } else {
        this.user.profession = this.professionSelection;
      }
    }
  },
  methods: {
    open() {
      this.role = null;
      if (!this.user.profession.trim()) {
        this.professionSelection = '';
      } else if (!this.professions.includes(this.user.profession)) {
        this.professionSelection = this.professions[this.professions.length - 1];
      } else {
        this.professionSelection = this.user.profession;
      }
      this.$refs.dialog.open();
    },
    close() {
      this.$refs.dialog.close();
    },
    roleName(roleId) {
      const results = this.roles.filter((r) => r.id === roleId);
      if (results.length > 0) {
        return results[0].name;
      } else {
        return 'Unknown';
      }
    },
    async save() {
      if (!this.valid) return;

      this.saving = true;
      const updated = await NoahTextAPI.updateUser(this.user);
      this.$emit('save', updated);
      this.$refs.dialog.close();
      this.saving = false;
    },
    addRole() {
      if (this.role && !this.user.roles.includes(this.role)) {
        this.user.roles.push(this.role);
      }
    }
  }
}
</script>

<style scoped>

</style>
