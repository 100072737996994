<template>
  <div id="feedback">
    <v-tooltip left>
      <template #activator="{ on, attrs }">
        <v-btn
            v-bind="attrs"
            v-on="on"
            color="secondary"
            elevation="4"
            fab
            bottom
            right
            fixed
            @click="open"
        >
          <v-icon>mdi-message-alert</v-icon>
        </v-btn>
      </template>

      <span>Submit Feedback</span>
    </v-tooltip>

    <Dialog ref="dialog" title="Submit Feedback">
      <v-form @submit.prevent="submit" v-model="valid">
        <v-select
            label="Category"
            class="mb-2"
            filled
            :items="categories"
            v-model="category"
            hide-details
        ></v-select>

        <v-textarea
            v-model="feedback"
            filled
            clearable
            label="Enter your feedback"
            autofocus
        ></v-textarea>

        <div class="d-flex justify-center align-center">
          <span class="text-subtitle-2">Choose a rating:</span>
          <v-rating
              v-model="rating"
              color="yellow darken-3"
              background-color="yellow darken-3"
              half-increments
              hover
              large
          ></v-rating>
        </div>

        <v-divider class="my-4"></v-divider>

        <div class="d-flex mt-2">
          <v-spacer></v-spacer>

          <v-btn text @click="$refs.dialog.close()">
            Cancel
          </v-btn>

          <v-btn
              text
              color="primary"
              @click="submit"
          >
            Submit
          </v-btn>
        </div>
      </v-form>
    </Dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Dialog from '@/components/general/Dialog';
import { NoahTextAPI } from '@/common/noahtext-api';

export default {
  name: 'Feedback',
  data: () => ({
    category: 'General',
    categories: [
        'General',
        'Bug Report',
        'Text Conversion',
        'Document Conversion',
        'Developer'
    ],
    feedback: '',
    rating: null,
    valid: true
  }),
  components: {Dialog},
  computed: mapGetters(['user']),
  methods: {
    async submit() {
      await NoahTextAPI.submitFeedback(this.feedback, this.category, this.rating ? this.rating.toString() : '-');
      this.$refs.dialog.close();
    },
    open() {
      this.feedback = '';
      this.rating = null;
      this.$refs.dialog.open();
    }
  }
}
</script>

<style scoped>

</style>