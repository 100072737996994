<template>
  <span>
    <span
        v-if="word.type === 'filler'"
        v-html="content"
        class="filler"
    ></span>
    <span
        v-else
        v-html="content"
        :class="guard(['nt:ManageWords']) ? { word: true, 'warning lighten-2': !!word.id && word.review.length > 0, 'error lighten-2': !word.id } : {}"
        @click="guard(['nt:ManageWords']) && $emit('click')"
    ></span>
  </span>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "Word",
  props: {
    word: Object,
    mode: Object
  },
  computed: {
    ...mapGetters(['guard']),
    content() {
      if (this.word.type === 'filler') {
        return this.word.content;
      } else {
        const data = this.word.content.split('');
        const vowels = data.map((c) => 'aeiou'.includes(c.toLowerCase()));

        // Underline long vowels
        if (this.mode.vowel) {
          for (const long of this.word.longVowels) {
            data[long] = `<u>${data[long]}</u>`;
          }
        }

        if (this.mode.highlight === 'rime') {
          // Bold rimes (first vowel + consonants of syllables)
          const word = [];

          let idx = 0;
          for (const end of this.word.syllables) {
            const rime = [];
            while (idx < end) {
              if (vowels[idx]) {
                rime.push(`<b>${data.slice(idx, end).join('')}</b>`);
                idx = end;
              } else {
                rime.push(data[idx]);
                idx++;
              }
            }
            word.push(rime.join(''));
          }

          return word.join('');
        } else if (this.mode.highlight === 'syllable' && this.word.syllables.length > 1) {
          // We have 2 or more syllables, bold alternatives
          const word = [];

          let idx = 0;
          let bold = true;
          for (const end of this.word.syllables) {
            if (bold) {
              word.push(`<b>${data.slice(idx, end).join('')}</b>`);
            } else {
              word.push(data.slice(idx, end).join(''));
            }
            bold = !bold;
            idx = end;
          }

          return word.join('');
        }
        return data.join('');
      }
    }
  }
}
</script>

<style scoped>
.word {
  transition-duration: 0.25s;
  border-radius: 2px;
  cursor: pointer;
}

.word:hover {
  background-color: rgba(100, 100, 100, 0.2);
}

.filler {
  white-space: pre;
  font-family: "Courier New", monospace;
}

hr {
  width: 100%;
  height: 0;
  margin: 0;
  flex-basis: 100%;
  border: 0;
}
</style>