<template>
  <v-app id="app">
    <AppHeader :drawer.sync="drawer"></AppHeader>

    <AppNavigation v-if="showNav" :drawer.sync="drawer"></AppNavigation>

    <v-main>
      <router-view></router-view>
    </v-main>

    <AppFooter></AppFooter>

    <Feedback></Feedback>

    <Snackbar ref="snackbar"></Snackbar>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import Snackbar from '@/components/general/Snackbar';
import AppNavigation from '@/components/general/AppNavigation';
import AppHeader from '@/components/general/AppHeader';
import AppFooter from '@/components/general/AppFooter';
import Feedback from '@/components/general/Feedback';

export default {
  name: 'App',
  components: {Feedback, AppFooter, Snackbar, AppNavigation, AppHeader},
  data: () => ({
    drawer: false
  }),
  async created() {
    // Load dark mode
    if (localStorage.darkMode) {
      this.$vuetify.theme.dark = JSON.parse(localStorage.darkMode);
    }
  },
  mounted() {
    this.$snackbar.init(this.$refs.snackbar);
  },
  computed: mapGetters(['showNav'])
};
</script>

<style>
.v-application--is-ltr .word-edit-dialog .v-input--selection-controls__input {
  margin-right: -4px;
  margin-left: -4px;
}

.v-application--is-ltr .word-edit-dialog .v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}

.transcription-syllable {
  font: 14pt "Courier New", monospace;
  line-height: 1.5;
  white-space: pre-wrap;
}

.transcription-rime {
  font: 20pt "Courier New", monospace;
  line-height: 1.5;
  white-space: pre-wrap;
}

.transcription-rime b {
  font-size: 24pt;
}

.transcription {
  padding: 16px;
}

.transcription p:last-child {
  margin-bottom: 0;
}
</style>
