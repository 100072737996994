var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',{attrs:{"items":_vm.users,"search-input":_vm.search,"value":_vm.value,"return-object":"","item-text":"email","item-value":"id","multiple":"","cache-items":"","filled":"","hide-details":"","label":"Search Users","prepend-inner-icon":"mdi-magnify"},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"input":function($event){return _vm.$emit('input', $event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var selected = ref.selected;
var select = ref.select;
return [_c('v-chip',_vm._b({attrs:{"color":"secondary","input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.removeUser(item)}}},'v-chip',attrs,false),[_vm._v(" "+_vm._s(item.firstName)+" "+_vm._s(item.lastName)+" ")])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.firstName)+" "+_vm._s(item.lastName))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.email))])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }