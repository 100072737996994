import Vue from 'vue';
import Router from 'vue-router';

import { authGuard } from '@/router/auth-guard';
import { titleGuard } from '@/router/title-guard';
import Home from '@/views/general/Home';
import Signup from '@/views/auth/Signup';
import Login from '@/views/auth/Login';
import ForgotPassword from '@/views/auth/ForgotPassword';
import ResetPassword from '@/views/auth/ResetPassword';
import Profile from '@/views/settings/Profile';
import Settings from '@/views/settings/Settings';
import Convert from '@/views/general/Convert';
import Documents from '@/views/general/Documents';
import Review from '@/views/management/Review';
import Roles from '@/views/admin/Roles';
import Users from '@/views/admin/Users';
import Permissions from '@/views/admin/Permissions';
import Dictionary from '@/views/management/Dictionary';
import Analytics from '@/views/admin/Analytics';
import FAQ from '@/views/general/FAQ';
import PrivacyPolicy from '@/views/general/PrivacyPolicy';
import TermsOfUse from '@/views/general/TermsOfUse';

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: '*',
      redirect: '/home'
    },
    {
      path: '/home',
      name: 'home',
      component: Home,
      meta: { title: 'Home', guards: [] }
    },
    {
      path: '/signup',
      name: 'signup',
      component: Signup,
      meta: { title: 'Sign Up', guards: [] }
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: { title: 'Log In', guards: [] }
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: ForgotPassword,
      meta: { title: 'Forgot Password', guards: [] }
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: ResetPassword,
      meta: { title: 'Reset Password', guards: ['nt:ResetPassword'] }
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
      meta: { title: 'Profile', guards: ['nt:Base'] }
    },
    {
      path: '/settings',
      name: 'settings',
      component: Settings,
      meta: { title: 'Settings', guards: ['nt:Base'] }
    },
    {
      path: '/convert',
      name: 'convert',
      component: Convert,
      meta: { title: 'Convert', guards: ['nt:Base'] }
    },
    {
      path: '/documents',
      name: 'documents',
      component: Documents,
      meta: { title: 'Documents', guards: ['nt:Base'] }
    },
    {
      path: '/faq',
      name: 'faq',
      component: FAQ,
      meta: { title: 'FAQ' }
    },
    {
      path: '/dictionary',
      name: 'dictionary',
      component: Dictionary,
      meta: { title: 'Dictionary', guards: ['nt:ManageWords'] }
    },
    {
      path: '/review',
      name: 'review',
      component: Review,
      meta: { title: 'Review', guards: ['nt:ManageWords'] }
    },
    {
      path: '/permissions',
      name: 'permissions',
      component: Permissions,
      meta: { title: 'Permissions', guards: ['nt:ManagePermissions'] }
    },
    {
      path: '/roles',
      name: 'roles',
      component: Roles,
      meta: { title: 'Roles', guards: ['nt:ManageRoles'] }
    },
    {
      path: '/users',
      name: 'users',
      component: Users,
      meta: { title: 'Users', guards: ['nt:ManageUsers'] }
    },
    {
      path: '/analytics',
      name: 'analytics',
      component: Analytics,
      meta: { title: 'Analytics', guards: ['nt:Administrator'] }
    },
    {
      path: '/privacy-policy',
      name: 'privacy-policy',
      component: PrivacyPolicy,
      meta: { title: 'Privacy Policy' }
    },
    {
      path: '/terms-of-use',
      name: 'terms-of-use',
      component: TermsOfUse,
      meta: { title: 'Terms of Use' }
    }
  ]
});

router.beforeEach(authGuard);
router.beforeEach(titleGuard);

export default router;
