<template>
  <Dialog ref="dialog" :title="`${newWord ? 'New' : 'Edit'} Word`">
    <template v-if="!loading" #extension>
      <v-tabs show-arrows v-model="tab">
        <v-tab
            v-for="word in words"
            :key="word.id"
            :href="`#${word.partOfSpeech}`"
        >
          {{word.partOfSpeech}}
          <v-btn icon color="error" @click="deleteWord(word)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-tab>
      </v-tabs>
    </template>

    <template v-if="!loading" #title>
      <v-select
          ref="partOfSpeechSelect"
          v-if="partsOfSpeech.length > 0"
          v-model="partOfSpeech"
          prepend-inner-icon="mdi-plus"
          outlined
          dense
          hide-details
          class="flex-grow-0"
          label="Add Part of Speech"
          :items="partsOfSpeech"
          @input="addPartOfSpeech($event)"
      ></v-select>
    </template>

    <div class="word-edit-dialog">
      <v-tabs-items v-if="!loading" v-model="tab">
        <v-tab-item
            v-for="word in words"
            :key="word.partOfSpeech"
            :value="word.partOfSpeech"
        >
          <span class="text-h6">Long Vowels</span>
          <v-tooltip v-if="word.review.includes('long')" top>
            <template #activator="{ on, attrs }">
              <v-icon
                  class="ml-2 mb-1"
                  color="warning"
                  v-on="on"
                  v-bind="attrs"
              >mdi-alert</v-icon>
            </template>

            <span>Needs Review</span>
          </v-tooltip>
          <p class="mb-0">Select long vowels to update.</p>

          <div class="wrapper">
            <v-btn-toggle
                v-if="word"
                v-model="word.longVowels"
                multiple
                color="secondary"
                class="vowels"
            >
              <v-btn
                  v-for="(char, idx) of word.content"
                  :key="idx"
                  :disabled="!vowel(char)"
                  class="letter"
              >
                <b>
                  <u v-if="word.longVowels.includes(idx)">{{char}}</u>
                  <span v-else>{{char}}</span>
                </b>
              </v-btn>
            </v-btn-toggle>
          </div>

          <v-divider class="my-4"></v-divider>

          <span class="text-h6">Syllables</span>
          <v-tooltip v-if="word.review.includes('syl')" top>
            <template #activator="{ on, attrs }">
              <v-icon
                  class="ml-2 mb-1"
                  color="warning"
                  v-on="on"
                  v-bind="attrs"
              >mdi-alert</v-icon>
            </template>

            <span>Needs Review</span>
          </v-tooltip>
          <p class="mb-0">Select syllable divisions to update.</p>

          <div class="wrapper">
            <div class="syllables">
              <span v-for="(part, idx) of syllableParts(word)" :key="idx">
                <v-chip
                    v-if="part.type === 'letter'"
                    label
                    class="letter syllable-letter"
                >
                  <b>{{part.value}}</b>
                </v-chip>
                <v-checkbox
                    v-else
                    dense
                    hide-details
                    color="secondary"
                    on-icon="mdi-drag-vertical-variant"
                    off-icon="mdi-minus"
                    :ripple="false"
                    :input-value="word.syllables.includes(part.value)"
                    :class="[word.syllables.includes(part.value) ? 'mx-4' : 'mx-0']"
                    @change="updateSyllable(word, part.value, $event)"
                ></v-checkbox>
              </span>
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>

      <div v-if="loading" class="text-center">
        <v-progress-circular indeterminate color="primary" :size="64"></v-progress-circular>
      </div>

      <v-divider class="my-4"></v-divider>

      <div class="d-flex">
        <v-spacer></v-spacer>

        <v-btn text @click="close">
          Cancel
        </v-btn>

        <v-btn
            text
            color="primary"
            :loading="saving"
            @click="save"
        >
          Save
        </v-btn>
      </div>
    </div>
  </Dialog>
</template>

<script>
import { NoahTextAPI } from '@/common/noahtext-api';
import { PARTS_OF_SPEECH } from '@/common/util';
import { cloneDeep } from 'lodash';
import Dialog from '@/components/general/Dialog';

export default {
  name: "WordEditDialog",
  components: { Dialog },
  props: {
    initialWord: Object
  },
  data: () => ({
    saving: false,
    loading: false,
    newWord: false,
    partOfSpeech: null,
    words: [],
    tab: ''
  }),
  computed: {
    partsOfSpeech() {
      return PARTS_OF_SPEECH.filter((part) => {
        for (const word of this.words) {
          if (word.partOfSpeech === part.value) return false;
        }
        return true;
      });
    }
  },
  methods: {
    async open() {
      this.$refs.dialog.open();

      this.loading = true;
      this.newWord = !this.initialWord.id;

      // Normalize content to account for possessive apostrophes
      let content = this.initialWord.content.toLowerCase();
      if (content.endsWith('\'')) {
        content = content.substring(0, content.length - 1);
      } else if(content.endsWith('\'s')) {
        content = content.substring(0, content.length - 2) + 's';
      }

      this.words = (await NoahTextAPI.get('words', {
        params: {
          content,
          limit: -1,
          order: 'partOfSpeech'
        }
      })).results;
      if (this.words.length === 0) {
        this.words = [{
          ...cloneDeep(this.initialWord),
          content
        }];
      }
      this.tab = this.initialWord.partOfSpeech;
      this.loading = false;
    },
    close() {
      this.$refs.dialog.close();

      this.words = [];
      this.tab = null;
    },
    vowel(char) {
      return 'aieou'.includes(char.toLowerCase());
    },
    updateSyllable(word, syllable, value) {
      if (value && !word.syllables.includes(syllable)) {
        word.syllables.push(syllable);
        word.syllables.sort((a, b) => a - b);
      } else if (!value) {
        word.syllables = word.syllables.filter((s) => syllable !== s);
      }
    },
    syllableParts(word) {
      const parts = [];
      for (let idx = 0; idx < word.content.length; idx++) {
        parts.push({
          type: 'letter',
          value: word.content[idx].toUpperCase()
        });
        if (idx !== word.content.length - 1) {
          parts.push({
            type: 'syllable',
            value: idx + 1
          });
        }
      }
      return parts;
    },
    addPartOfSpeech(part) {
      const previous = this.words.length > 0 ? this.words[0] : this.initialWord;
      this.words = this.words
          .concat([{
            ...cloneDeep(previous),
            id: null,
            content: previous.content.toLowerCase(),
            partOfSpeech: part
          }])
          .sort((a, b) => a.partOfSpeech < b.partOfSpeech ? -1 : 1);

      this.$refs.partOfSpeechSelect.blur();
      this.$nextTick(() => {
        this.tab = part;
        this.partOfSpeech = null;
      });
    },
    async save() {
      this.saving = true;
      try {
        const updated = [];
        for (const word of this.words) {
          const method = word.id ? NoahTextAPI.updateWord : NoahTextAPI.createWord;
          updated.push(await method({
            ...word,
            review: []
          }));
        }

        this.$snackbar.success('Successfully updated word!');
        if (updated.length > 0) {
          this.$emit('save', updated);
        }
        this.close();
      } catch {
        this.$snackbar.error('Error updating word.');
      }
      this.saving = false;
    },
    async deleteWord(word) {
      if (confirm('Are you sure you want to delete this part of speech?')) {
        // Actually delete the word
        if (word.id) {
          await NoahTextAPI.deleteWord(word);
          this.$emit('delete', word);
        }

        // Update tabs
        const updated = this.words.filter((w) => w != word);
        if (this.tab === word.partOfSpeech) {
          this.tab = updated.length > 0 ? updated[0].partOfSpeech : null;
        }
        this.$nextTick(() => this.words = updated);
      }
    }
  }
}
</script>

<style scoped>
.wrapper {
  max-width: 100%;
  overflow-x: auto;
  padding: 4px;
}

.syllables {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.syllable-letter {
  padding: 20px 16px;
}

.letter {
  font: 14pt "Courier New", monospace !important;
}
</style>
