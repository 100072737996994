<template>
  <div id="forgot-password">
    <AppContent>
      <v-card class="mx-auto" max-width="400">
        <v-toolbar dark color="secondary">
          <v-toolbar-title>Password Recovery</v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <v-form v-model="valid" @submit.prevent="forgotPassword">
            <v-alert v-if="finished" type="success" text dense border="left">
              An email with instructions to reset your password has been sent to {{email}}
            </v-alert>

            <v-text-field
                v-model="email"
                :rules="[v => !!v.trim() || 'Email is required']"
                autofocus
                filled
                label="Email"
                required
            ></v-text-field>

            <v-btn
                :disabled="!valid"
                :loading="loading"
                type="submit"
                block
                color="primary"
            >
              Reset Password
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </AppContent>
  </div>
</template>

<script>
import { NoahTextAPI } from '@/common/noahtext-api';
import AppContent from '@/components/general/AppContent';

export default {
  name: 'ForgotPassword',
  components: {AppContent},
  data: () => ({
    valid: false,
    loading: false,
    finished: false,
    email: ''
  }),
  methods: {
    async forgotPassword() {
      this.loading = true;
      await NoahTextAPI.forgotPassword(this.email);
      this.loading = false;
      this.finished = true;
    }
  }
}
</script>

<style scoped>

</style>