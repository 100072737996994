<template>
  <div id="review">
    <v-tabs v-model="tab">
      <v-tab key="words">Words</v-tab>
      <v-tab key="changes">Changes</v-tab>
    </v-tabs>

    <AppContent>
      <v-tabs-items v-model="tab">
        <v-tab-item key="words">
          <ReviewWords></ReviewWords>
        </v-tab-item>
        <v-tab-item key="changes">
          <ReviewChanges></ReviewChanges>
        </v-tab-item>
      </v-tabs-items>
    </AppContent>
  </div>
</template>

<script>

import AppContent from '@/components/general/AppContent';
import ReviewChanges from '@/views/management/ReviewChanges';
import ReviewWords from '@/views/management/ReviewWords';

export default {
  name: 'Review',
  components: {AppContent, ReviewChanges, ReviewWords },
  data: () => ({
    tab: 'words'
  }),
}
</script>

<style scoped>

</style>
