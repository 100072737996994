<template>
  <v-container :fluid="authed">
    <slot></slot>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AppContent',
  computed: mapGetters(['authed'])
}
</script>

<style scoped>

</style>