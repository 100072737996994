var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"review-changes"}},[_c('PageHeader',{attrs:{"title":"Review Changes"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"filled":"","label":"Date Range","prepend-inner-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{attrs:{"color":"secondary","range":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.changes,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"footer-props":{ 'items-per-page-options': [5, 10, 25, 50, 100] },"sort-by":"created","sort-desc":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.content",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"word"},[_c('b',[_vm._v(" "+_vm._s(item.word.content[0].toUpperCase() + item.word.content.slice(1))+" ")])])]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.user.firstName)+" "+_vm._s(item.user.lastName))]),_c('br'),_c('span',[_vm._v(_vm._s(item.user.email))])]}},{key:"item.syllables",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"word",domProps:{"innerHTML":_vm._s(_vm.syllables(item.word))}})]}},{key:"item.longVowels",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"word",domProps:{"innerHTML":_vm._s(_vm.longVowels(item.word))}})]}},{key:"item.partOfSpeech",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.partsOfSpeech[item.word.partOfSpeech])+" ")]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created))+" ")]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"warning"},on:{"click":function($event){return _vm.editWord(item.word)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.deleteWord(item.word)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])}),_c('WordEditDialog',{ref:"wordEditDialog",attrs:{"initial-word":_vm.word},on:{"save":function($event){return _vm.refresh()},"delete":function($event){return _vm.refresh()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }