<template>
  <div id="home">
    <v-container class="section">
      <div :style="{ cursor: 'pointer' }" @click="$router.push({ name: 'signup' })">
        <v-alert
            class="text-h6 mb-4"
            color="primary"
            dark
            icon="mdi-lightbulb"
            :elevation="4"
        >
          Sign up for a <b><i>free</i></b> account to convert documents, unlock larger conversions, and more!
        </v-alert>
      </div>

      <v-row>
        <v-col cols="12" md="6">
          <v-sheet elevation="4">
            <v-img src="@/assets/example.gif"></v-img>
          </v-sheet>
        </v-col>
        <v-col class="d-flex align-center" cols="12" md="6">
          <div>
            <h3 class="text-h3">Use our learning tools to help dyslexic, striving, and multilingual readers</h3>
            <p>Noah Text® allows readers to see sound-parts within words, providing a way for striving readers to decode and enunciate words that are too difficult to access. In turn, the improvement in reading accuracy and fluency free up cognitive resources readers can devote to comprehending the meaning of the text, enabling them to truly enjoy reading while building reading skill!</p>
            <p>With our new StrongReader&trade; Builder tool, individuals and teachers can convert pasted text from any device into Noah Text®, as well as convert Word documents. Imagine the possibilities!</p>
            <ul class="mb-2">
              <li>Reading passages</li>
              <li>Articles & reports from the web</li>
              <li>Worksheets</li>
              <li>Tests and quizzes</li>
              <li>Anything you want to differentiate and individualize!</li>
            </ul>

            <div class="d-flex">
              <v-btn @click="tryItOut" color="secondary" class="mr-2" x-large>Get Started</v-btn>
              <v-btn @click="$refs.soundKeyDialog.open()" x-large>How it works</v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-sheet :color="sectionClass">
      <v-container ref="tryitout" class="section">
        <PageHeader :divider="false" title="Try it out!"></PageHeader>
        <p class="mb-0">Type or copy some text into the field below and click <b>Convert</b> to see it in action.</p>
        <p>To unlock larger conversions, <router-link :to="{ name: 'signup' }">sign up for a <b>free</b> account</router-link>!</p>

        <v-textarea
            class="mb-2"
            v-model="text"
            filled
            clearable
            :counter="maxInput"
            label="Enter text to convert to Noah Text®"
        ></v-textarea>

        <v-row>
          <v-col cols="12" sm="9">
            <v-btn
                block
                color="primary"
                :disabled="!text || !text.trim()"
                :loading="loading"
                @click="convert"
            >
              <v-icon>mdi-format-text-rotation-none</v-icon>
              Convert
            </v-btn>
          </v-col>
          <v-col cols="12" sm="3">
            <v-btn
                block
                color="secondary"
                :disabled="transcript.length === 0"
                @click="copy"
            >
              <v-icon>mdi-content-copy</v-icon>
              <span v-if="copied">Copied!</span>
              <span v-else>Copy</span>
            </v-btn>
          </v-col>
        </v-row>

        <v-card v-if="!loading && transcript.length > 0" class="mt-4">
          <v-card-title v-if="improvement !== null">StrongReader has improved readability by altering {{(improvement * 100).toFixed(0)}}% of the words in this text.</v-card-title>
          <v-divider></v-divider>
          <div class="transcription-syllable transcription" ref="transcription" v-html="html"></div>
        </v-card>
      </v-container>
    </v-sheet>

    <div class="carousel">
      <div class="carousel-image"></div>

      <v-carousel v-model="carousel" cycle>
        <v-carousel-item v-for="(test, idx) in testimonials" :key="idx">
          <div class="d-flex align-center justify-center fill-height">
            <div>
              <h4 class="text-h4 text-center">{{test.content}}</h4>
              <p class="text-subtitle-1 text-center">{{test.author}}</p>
            </div>
          </div>
        </v-carousel-item>
      </v-carousel>
    </div>

    <v-container class="section">
      <PageHeader title="Awards" :divider="false"></PageHeader>

      <p>Noah Text® has received ongoing recognition and support from literacy specialists and education professionals since its release in 2016, and was selected as an Innovation Showcase Panelist by the Software & Information Industry Association (SIIA), Education Business Forum, in 2017. See <a href="https://noahtext.com/reviews/" target="_blank">reviews</a> and <a href="https://noahtext.com/board-of-advisors/" target="_blank">advisory board members</a>.</p>

      <v-row align="center" justify="center">
        <v-col cols="12" md="4">
          <v-img src="@/assets/siia.png"></v-img>
        </v-col>
      </v-row>
    </v-container>

    <v-sheet :color="sectionClass">
      <v-container class="section">
        <PageHeader title="Partners" :divider="false"></PageHeader>

        <v-row>
          <v-col v-for="(part, idx) in partners" :key="idx" cols="12" md="4">
            <v-card>
              <v-img :src="require(`@/assets/partners/${part.image}`)" height="256px" contain></v-img>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>

    <div class="video-wrapper">
      <iframe width="1905" height="784" src="https://www.youtube.com/embed/N9r_l1rd8K0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>

    <SoundKey ref="soundKeyDialog"></SoundKey>
  </div>
</template>

<script>
import { NoahTextAPI } from '@/common/noahtext-api';
import SoundKey from '@/components/general/SoundKey';
import PageHeader from '@/components/general/PageHeader';

export default {
  name: 'Convert',
  components: {PageHeader, SoundKey},
  data: () => ({
    text: '',
    loading: false,
    transcript: [],
    improvement: null,
    copied: false,
    copiedTimeout: null,
    maxInput: 1000,
    carousel: '0',
    testimonials: [
      {
        content: 'If all books were in Noah Text®, I would read everything. It makes reading so much easier!',
        author: 'High School Student/Striving Reader'
      },
      {
        content: 'I am extremely pleased with how Noah Text® helps the striving reader come into his own as a proficient reader.',
        author: 'Jennifer Smeltser, The Old Schoolhouse Magazine'
      },
      {
        content: 'My students are showing remarkable improvement especially in confidence in decoding unfamiliar words during reading.',
        author: 'Norma Wallace, Teacher, Northern State Prison, NJ'
      }
    ],
    partners: [
      {
        name: 'New Century Education',
        image: 'newcentury.png'
      },
      {
        name: 'Green Kids Club',
        image: 'greenkids.png'
      },
      {
        name: 'Rhode Island Tutorial & Educational Services',
        image: 'rites.png'
      },
      {
        name: 'The Mad Dash',
        image: 'themaddash.png'
      },
      {
        name: 'Correctional Education Association Region One',
        image: 'cearo.png'
      },
      {
        name: 'Educational Advisor',
        image: 'advisor.png'
      },
      {
        name: 'Mackin VIA',
        image: 'mackin.png'
      }
    ]
  }),
  computed: {
    html() {
      return this.transcript.map((t) => `<p>${t.trim() ? t : t + '<br>'}</p>`).join('');
    },
    sectionClass() {
      return this.$vuetify.theme.dark ? 'secondary darken-4' : 'green lighten-5';
    }
  },
  methods: {
    async convert() {
      this.loading = true;
      const response = await NoahTextAPI.convertHTMLFree(this.text.slice(0, this.maxInput).split('\n'));
      this.improvement = response.improvement;
      this.transcript = response.transcript;
      this.loading = false;
    },
    copy() {
      // Clear the cooldown on the previous message
      clearTimeout(this.copied);

      // Manually copy the contents of the transcription
      const range = document.createRange();
      range.selectNode(this.$refs.transcription);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand('copy');
      window.getSelection().removeAllRanges();

      // Display a "copied!" message
      this.copied = true;
      this.copiedTimeout = setTimeout(() => this.copied = false, 2500);
    },
    tryItOut() {
      const section = this.$refs.tryitout;
      const top = section.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({ top: top - 64, behavior: 'smooth' });
    }
  }
}
</script>

<style scoped>
.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.carousel {
  position: relative;
}

.carousel-image {
  background-image: url("../../assets/back.png");
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  filter: brightness(25%);
}

.section {
  padding: 32px;
}
</style>