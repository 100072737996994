<template>
  <div class="page-header">
    <div class="d-flex">
      <h4 class="text-h4">{{title}}</h4>
      <div class="flex-grow-1"></div>
      <slot></slot>
    </div>

    <v-divider v-if="divider"></v-divider>
  </div>
</template>

<script>
export default {
  name: "PageHeader",
  props: {
    title: {
      type: String,
      required: true
    },
    divider: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped>
.page-header {
  margin-bottom: 8px;
}

.page-header > div {
  display: flex;
}

.page-header > div h1 {
  flex-grow: 1;
}
</style>