<template>
  <div id="documents">
    <AppContent>
      <PageHeader title="Convert Documents">
        <v-btn color="primary" @click="$refs.uploadDocumentDialog.open()">
          <v-icon class="mr-2">mdi-upload</v-icon>
          Upload Documents
        </v-btn>
      </PageHeader>

      <v-alert
          color="primary"
          border="left"
          dense
          text
      >
        <p class="mb-0">StrongReader supports conversions for modern Microsoft Word documents (.docx).</p>
      </v-alert>

      <v-data-table
          :headers="headers"
          :items="documents"
          :options.sync="options"
          :server-items-length="total"
          :loading="loading"
          :footer-props="{ 'items-per-page-options': [5, 10, 25, 50, 100] }"
          sort-by="created"
          sort-desc
      >
        <template #item.created="{ item }">
          {{formatDate(item.created)}}
        </template>

        <template #item.status="{ item }">
          <v-tooltip top>
            <template #activator="{ on }">
              <span v-on="on">
                <v-icon
                    v-if="item.error"
                    color="error"
                >mdi-alert-circle</v-icon>
                <v-progress-circular
                    v-else-if="item.progress < 1"
                    :value="item.progress * 100"
                    size="24"
                    color="secondary"
                ></v-progress-circular>
                <v-btn
                    v-else
                    icon
                    color="primary"
                    @click="downloadDocument(item)"
                >
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </span>
            </template>

            <div v-if="item.error">Error: {{item.error}}</div>
            <div v-else-if="item.progress < 1">Processing...</div>
            <div v-else>Download</div>
          </v-tooltip>
        </template>

        <template #item.size="{ item }">
          <span v-if="!item.error && item.progress === 1">
            {{byteSize(item.size)}}
          </span>
          <span v-else>-</span>
        </template>

        <template #item.retry="{ item }">
          <v-btn :disabled="!item.error && item.progress < 1" icon color="primary" @click="convertDocument(item)">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>

        <template #item.edit="{ item }">
          <v-btn icon color="warning" @click="editDocument(item)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>

        <template #item.delete="{ item }">
          <v-btn icon color="error" @click="deleteDocument(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>

      <UploadDocumentDialog
          ref="uploadDocumentDialog"
          @save="refresh"
      ></UploadDocumentDialog>

      <DocumentEditDialog
          ref="editDocumentDialog"
          :document="document"
          @save="refresh"
      ></DocumentEditDialog>
    </AppContent>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import { NoahTextAPI } from '@/common/noahtext-api';
import { DEFAULT_DOCUMENT, byteSize } from '@/common/util';
import { format } from 'date-fns';
import AppContent from '@/components/general/AppContent';
import DocumentEditDialog from '@/components/documents/DocumentEditDialog';
import UploadDocumentDialog from '@/components/documents/DocumentUploadDialog';
import PageHeader from '@/components/general/PageHeader';

export default {
  name: 'Documents',
  components: {AppContent, DocumentEditDialog, UploadDocumentDialog, PageHeader },
  data: () => ({
    loading: false,
    documents: [],
    document: DEFAULT_DOCUMENT(),
    refreshTimeout: null,
    total: 0,
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Size', value: 'size' },
      { text: 'Uploaded', value: 'created' },
      { text: 'Status', value: 'status', sortable: false },
      { text: 'Retry', value: 'retry', sortable: false },
      { text: 'Edit', value: 'edit', sortable: false },
      { text: 'Delete', value: 'delete', sortable: false }
    ],
    options: {},
    byteSize
  }),
  beforeDestroy() {
    clearTimeout(this.refreshTimeout);
  },
  computed: mapGetters(['user']),
  watch: {
    options() {
      this.refresh();
    }
  },
  methods: {
    async refresh(showLoading = true) {
      clearTimeout(this.refreshTimeout);

      let { sortBy, sortDesc, page, itemsPerPage } = this.options;

      if (showLoading) this.loading = true;
      const response = await NoahTextAPI.get('documents', {
        params: {
          userId: this.user.id,
          offset: (page - 1) * itemsPerPage,
          limit: itemsPerPage,
          order: sortBy.length > 0 ? `${sortDesc[0] ? '-' : ''}${sortBy[0]}` : undefined
        }
      });

      this.total = response.count;
      this.documents = response.results;
      this.loading = false;

      this.refreshTimeout = setTimeout(() => this.refresh(false), 5000);
    },
    editDocument(document) {
      this.document = cloneDeep(document);
      this.$nextTick(() => this.$refs.editDocumentDialog.open());
    },
    async downloadDocument(document) {
      const url = (await NoahTextAPI.downloadDocument(document)).url;
      window.open(url, '_blank');
    },
    async convertDocument(document) {
      clearTimeout(this.refreshTimeout);

      document.progress = 0;
      document.error = null;
      await NoahTextAPI.convertDocument(document);

      this.refreshTimeout = setTimeout(() => this.refresh(false), 5000);
    },
    async deleteDocument(document) {
      if (confirm('Are you sure you want to delete this document?')) {
        await NoahTextAPI.deleteDocument(document);
        await this.refresh();
      }
    },
    formatDate(date) {
      return format(new Date(date), 'MMM d, yyyy p');
    }
  }
}
</script>

<style scoped>

</style>
