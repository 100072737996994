<template>
  <v-snackbar
    v-model="snackbar"
    :timeout="timeout"
    :color="color"
  >
    {{ message }}
    <v-btn text @click="snackbar = false">
      Close
    </v-btn>
  </v-snackbar>
</template>

<script>
  export default {
    name: 'Snackbar',
    data: () => ({
      snackbar: false,
      message: '',
      color: 'info',
      timeout: 4000
    }),
    methods: {
      open(message, color='info', timeout=4000) {
        this.snackbar = false;
        this.$nextTick(() => {
          this.timeout = timeout;
          this.message = message;
          this.color = color;
          this.snackbar = true;
        });
      }
    }
  };
</script>

<style scoped>

</style>
