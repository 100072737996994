import { render, staticRenderFns } from "./FAQResultViewDialog.vue?vue&type=template&id=330a280c&scoped=true&"
import script from "./FAQResultViewDialog.vue?vue&type=script&lang=js&"
export * from "./FAQResultViewDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "330a280c",
  null
  
)

export default component.exports