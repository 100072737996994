import axios from 'axios';
import store from '../store';
import app from '../main';
import { logout } from '../common/util';

const client = axios.create({
  baseURL: process.env.VUE_APP_NOAHTEXT_API
});

export class NoahTextAPI {
  static async call(method, url, data, config = {}) {
    try {
      // Set up options
      const options = {
        url,
        method,
        data,
        ...config
      };

      // Apply API token
      const token = store.state.user.token;
      if (token) {
        options.headers = { 'Authorization': token };
      }

      // Get response
      const response = await client.request(options);
      if (config.success) {
        app.$snackbar.success(config.success);
      }
      return response.data;
    } catch(err) {
      if (err.response && err.response.status === 401) {
        // The token has expired - log in again
        await logout('login');
      } else if (err.response && err.response.data) {
        // A general error
        if (config.error) {
          app.$snackbar.error(`${config.error} ${err.response.data.error}`);
        }
        throw err.response.data.error;
      } else {
        // An unknown error
        if (config.error) {
          app.$snackbar.error(config.error);
        }
        throw 'Server error';
      }
    }
  }

  static async get(url, config) {
    return await NoahTextAPI.call('get', url, undefined, config);
  }

  static async post(url, data, config) {
    return await NoahTextAPI.call('post', url, data, config);
  }

  static async put(url, data, config) {
    return await NoahTextAPI.call('put', url, data, config);
  }

  static async delete(url, data, config) {
    return await NoahTextAPI.call('delete', url, data, config);
  }

  static async convert(text, convertUnknown) {
    app.$gtag.event('convert-raw', {
      event_category: 'convert',
      value: text.length
    });
    return await NoahTextAPI.post('convert', { text }, {
      params: {
        convertUnknown
      },
      error: 'Error converting text.'
    });
  }

  static async convertHTML(text, highlight = 'syllable', vowel = true) {
    app.$gtag.event('convert-html', {
      event_category: 'convert',
      value: text.length
    });
    return await NoahTextAPI.post('convert-html', { text }, {
      params: {
        highlight,
        vowel
      },
      error: 'Error converting text.'
    });
  }

  static async convertHTMLFree(text) {
    app.$gtag.event('convert-html-free', {
      event_category: 'convert',
      value: text.length
    });
    return await NoahTextAPI.post('convert-html-free', { text }, {
      error: 'Error converting text.'
    });
  }

  static async createWord(word) {
    app.$gtag.event('word-create', {
      event_category: 'words',
      event_label: word.content
    });
    return await NoahTextAPI.post('words', word);
  }

  static async updateWord(word) {
    app.$gtag.event('word-update', {
      event_category: 'words',
      event_label: word.content,
      value: word.id
    });
    return await NoahTextAPI.put(`words/${word.id}`, word);
  }

  static async deleteWord(word) {
    app.$gtag.event('word-delete', {
      event_category: 'words',
      event_label: word.content,
      value: word.id
    });
    return await NoahTextAPI.delete(`words/${word.id}`, undefined, {
      success: 'Successfully deleted word!',
      error: 'Error deleting word.'
    });
  }

  static async uploadDocuments(data) {
    app.$gtag.event('document-upload', {
      event_category: 'documents',
      value: data.files.length
    });
    return await NoahTextAPI.post('documents/upload', data);
  }

  static async downloadDocument(document) {
    app.$gtag.event('document-download', {
      event_category: 'documents',
      value: document.id
    });
    return await NoahTextAPI.get(`documents/${document.id}/download`);
  }

  static async convertDocument(document) {
    app.$gtag.event('document-convert', {
      event_category: 'documents',
      value: document.id
    });
    return await NoahTextAPI.post(`documents/${document.id}/convert`);
  }

  static async updateDocument(document) {
    app.$gtag.event('document-update', {
      event_category: 'documents',
      event_label: document.name,
      value: document.id
    });
    return await NoahTextAPI.put(`documents/${document.id}`, document, {
      success: 'Successfully updated document!',
      error: 'Error updating document.'
    });
  }

  static async deleteDocument(document) {
    app.$gtag.event('document-delete', {
      event_category: 'documents',
      event_label: document.name,
      value: document.id
    });
    return await NoahTextAPI.delete(`documents/${document.id}`, undefined, {
      success: 'Successfully deleted document!',
      error: 'Error deleting document.'
    });
  }

  static async createFAQCategory(category) {
    app.$gtag.event('faq-category-create', {
      event_category: 'faq',
      event_label: category.name
    });
    return await NoahTextAPI.post('faq/categories', category, {
      success: 'Successfully created FAQ category!',
      error: 'Error creating FAQ category.'
    });
  }

  static async updateFAQCategory(category) {
    app.$gtag.event('faq-category-update', {
      event_category: 'faq',
      event_label: category.name,
      value: category.id
    });
    return await NoahTextAPI.put(`faq/categories/${category.id}`, category, {
      success: 'Successfully updated FAQ category!',
      error: 'Error updating FAQ category.'
    });
  }

  static async deleteFAQCategory(category) {
    app.$gtag.event('faq-category-delete', {
      event_category: 'faq',
      event_label: category.name,
      value: category.id
    });
    return await NoahTextAPI.delete(`faq/categories/${category.id}`, undefined, {
      success: 'Successfully deleted FAQ category!',
      error: 'Error deleting FAQ category.'
    });
  }

  static async createFAQResult(result) {
    app.$gtag.event('faq-result-create', {
      event_category: 'faq',
      event_label: result.question
    });
    return await NoahTextAPI.post('faq/results', result, {
      success: 'Successfully created FAQ result!',
      error: 'Error creating FAQ result.'
    });
  }

  static async updateFAQResult(result) {
    app.$gtag.event('faq-result-update', {
      event_category: 'faq',
      event_label: result.question,
      value: result.id
    });
    return await NoahTextAPI.put(`faq/results/${result.id}`, result, {
      success: 'Successfully updated FAQ result!',
      error: 'Error updating FAQ result.'
    });
  }

  static async deleteFAQResult(result) {
    app.$gtag.event('faq-result-delete', {
      event_category: 'faq',
      event_label: result.question,
      value: result.id
    });
    return await NoahTextAPI.delete(`faq/results/${result.id}`, undefined, {
      success: 'Successfully deleted FAQ result!',
      error: 'Error deleting FAQ result.'
    });
  }

  static async createToken(token) {
    app.$gtag.event('token-create', {
      event_category: 'tokens',
      event_label: token.description
    });
    return await NoahTextAPI.post('tokens', token, {
      success: 'Successfully created token!',
      error: 'Error creating token.'
    });
  }

  static async updateToken(token) {
    app.$gtag.event('token-update', {
      event_category: 'tokens',
      event_label: token.description,
      value: token.id
    });
    return await NoahTextAPI.put(`tokens/${token.id}`, token, {
      success: 'Successfully updated token!',
      error: 'Error updating token.'
    });
  }

  static async deleteToken(token) {
    app.$gtag.event('token-delete', {
      event_category: 'tokens',
      event_label: token.description,
      value: token.id
    });
    return await NoahTextAPI.delete(`tokens/${token.id}`, undefined, {
      success: 'Successfully deleted token!',
      error: 'Error deleting token.'
    });
  }

  static async regenerateToken(token) {
    app.$gtag.event('token-regenerate', {
      event_category: 'tokens',
      event_label: token.description,
      value: token.id
    });
    return await NoahTextAPI.put(`tokens/${token.id}/regenerate`, undefined, {
      success: 'Successfully regenerated token!',
      error: 'Error regenerating token.'
    });
  }

  static async createPermission(permission) {
    app.$gtag.event('permission-create', {
      event_category: 'permissions',
      event_label: permission.name
    });
    return await NoahTextAPI.post('permissions', permission, {
      success: 'Successfully created permission!',
      error: 'Error creating permission.'
    });
  }

  static async updatePermission(permission) {
    app.$gtag.event('permission-update', {
      event_category: 'permissions',
      event_label: permission.name,
      value: permission.id
    });
    return await NoahTextAPI.put(`permissions/${permission.id}`, permission, {
      success: 'Successfully updated permission!',
      error: 'Error updating permission.'
    });
  }

  static async deletePermission(permission) {
    app.$gtag.event('permission-delete', {
      event_category: 'permissions',
      event_label: permission.name,
      value: permission.id
    });
    return await NoahTextAPI.delete(`permissions/${permission.id}`, undefined, {
      success: 'Successfully deleted permission!',
      error: 'Error deleting permission.'
    });
  }

  static async createRole(role) {
    app.$gtag.event('role-create', {
      event_category: 'roles',
      event_label: role.name
    });
    return await NoahTextAPI.post('roles', role, {
      success: 'Successfully created role!',
      error: 'Error creating role.'
    });
  }

  static async updateRole(role) {
    app.$gtag.event('role-update', {
      event_category: 'roles',
      event_label: role.name,
      value: role.id
    });
    return await NoahTextAPI.put(`roles/${role.id}`, role, {
      success: 'Successfully updated role!',
      error: 'Error updating role.'
    });
  }

  static async deleteRole(role) {
    app.$gtag.event('role-delete', {
      event_category: 'roles',
      event_label: role.name,
      value: role.id
    });
    return await NoahTextAPI.delete(`roles/${role.id}`, role, {
      success: 'Successfully deleted role!',
      error: 'Error deleting role.'
    });
  }

  static async updateUser(user) {
    app.$gtag.event('user-update', {
      event_category: 'users',
      value: user.id
    });
    return await NoahTextAPI.put(`users/${user.id}`, user, {
      success: 'Successfully updated user!',
      error: 'Error updating user.'
    });
  }

  static async deleteUser(user) {
    app.$gtag.event('user-delete', {
      event_category: 'users',
      value: user.id
    });
    return await NoahTextAPI.delete(`users/${user.id}`, user, {
      success: 'Successfully deleted user!',
      error: 'Error deleting user.'
    });
  }

  static async updateProfile(user) {
    app.$gtag.event('account-update-profile', {
      event_category: 'account'
    });
    return await NoahTextAPI.put('users/profile', user, {
      success: 'Successfully updated profile!',
      error: 'Error updating profile.'
    });
  }

  static async forgotPassword(email) {
    app.$gtag.event('account-forgot-password', {
      event_category: 'account'
    });
    return await NoahTextAPI.post('users/forgot-password', { email }, {
      success: 'Successfully sent password recovery email!',
      error: 'Error sending password recovery email.'
    });
  }

  static async resetPassword(password) {
    app.$gtag.event('account-reset-password', {
      event_category: 'account'
    });
    return await NoahTextAPI.post('users/reset-password', { password }, {
      success: 'Successfully reset password!',
      error: 'Error resetting password.'
    });
  }

  static async changePassword(oldPassword, password) {
    app.$gtag.event('account-change-password', {
      event_category: 'account'
    });
    return await NoahTextAPI.post('users/change-password', { oldPassword, password }, {
      success: 'Successfully changed password!',
      error: 'Error changing password.'
    });
  }

  static async submitFeedback(feedback, category, rating) {
    app.$gtag.event('account-submit-feedback', {
      event_category: 'account'
    });
    return await NoahTextAPI.post('users/submit-feedback', { feedback, category, rating }, {
      success: 'Successfully submitted feedback!',
      error: 'Error submitting feedback.'
    });
  }

  static async signup(form) {
    app.$gtag.event('account-signup', {
      event_category: 'account'
    });
    return await NoahTextAPI.post('users/signup', form, {
      success: 'Thanks for signing up! Welcome to StrongReader.',
      error: 'Error creating account.'
    });
  }

  static async login(form) {
    app.$gtag.event('account-login', {
      event_category: 'account'
    });
    return await NoahTextAPI.post('users/login', form, {
      success: 'Successfully logged in!',
      error: 'Error logging in.'
    });
  }

  static async refresh() {
    app.$gtag.event('account-refresh', {
      event_category: 'account'
    });
    return await NoahTextAPI.post('users/refresh', {}, {
      error: 'Error logging in.'
    });
  }

  static async analyticsUserLogins(ranges, type, users) {
    return await NoahTextAPI.post('analytics/user-logins', {
      ranges,
      type,
      users: users.map((user) => user.id)
    }, {
      error: 'Error fetching logins report.'
    });
  }

  static async analyticsUserConversions(ranges, type, users) {
    return await NoahTextAPI.post('analytics/user-conversions', {
      ranges,
      type,
      users: users.map((user) => user.id)
    }, {
      error: 'Error fetching conversions report.'
    });
  }

  static async analyticsUserDocConversions(ranges, type, users) {
    return await NoahTextAPI.post('analytics/user-doc-conversions', {
      ranges,
      type,
      users: users.map((user) => user.id)
    }, {
      error: 'Error fetching document conversions report.'
    });
  }

  static async analyticsUserWordEdits(ranges, type, userType, users) {
    return await NoahTextAPI.post('analytics/user-word-edits', {
      ranges,
      type,
      userType,
      users: users.map((user) => user.id)
    }, {
      error: 'Error fetching words report.'
    });
  }
}
