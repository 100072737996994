<template>
  <div id="signup">
    <AppContent>
      <v-card class="mx-auto" max-width="800">
        <v-toolbar dark color="secondary">
          <v-toolbar-title>Sign Up</v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <v-alert color="primary" dark>
            <h3 class="mb-2">By signing up for a <b><i>free</i></b> account, you get the ability to:</h3>
            <div><v-icon>mdi-check</v-icon> Convert large blocks of text</div>
            <div><v-icon>mdi-check</v-icon> Convert documents</div>
            <div><v-icon>mdi-check</v-icon> Use advanced Noah Text&reg; options</div>
            <div><v-icon>mdi-check</v-icon> And much more!</div>
          </v-alert>

          <v-form v-model="valid" @submit.prevent="signup">
            <v-alert v-if="error" type="error" text dense border="left">
              {{error}}
            </v-alert>

            <v-row dense>
              <v-col cols="12" sm="6">
                <v-text-field
                    v-model="firstName"
                    :rules="[v => !!v.trim() || 'First Name is required']"
                    autofocus
                    filled
                    label="First Name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                    v-model="lastName"
                    :rules="[v => !!v.trim() || 'Last Name is required']"
                    filled
                    label="Last Name"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                    v-model="email"
                    :rules="[v => !!emailRegex.exec(v) || 'Invalid email address']"
                    filled
                    label="Email"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <v-select
                    v-model="professionSelection"
                    filled
                    :items="professions"
                    label="Profession (Optional)"
                    :hide-details="professionSelection.startsWith('Other')"
                    clearable
                ></v-select>
                <v-text-field
                    v-if="professionSelection.startsWith('Other')"
                    v-model="profession"
                    filled
                    label="Profession"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                    v-model="business"
                    filled
                    label="Business Name (Optional)"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                    v-model="password"
                    :rules="passwordRules"
                    type="password"
                    filled
                    label="Password"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                    v-model="passwordConfirm"
                    :rules="[v => v === password || 'Passwords do not match']"
                    type="password"
                    filled
                    label="Confirm Password"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-checkbox class="mb-4 mt-0" hide-details :rules="[v => v || 'You must agree to the terms and conditions']">
              <template #label>
                <div>
                  Noah Text, LLC has the right to relinquish your ability to access this tool for any reason at any time. <b>You agree to the <router-link :to="{ name: 'terms-of-use' }">terms of use</router-link> and acknowledge our <router-link :to="{ name: 'privacy-policy' }">privacy policy</router-link> by checking this box.</b>
                </div>
              </template>
            </v-checkbox>

            <v-btn
                :disabled="!valid"
                :loading="loading"
                type="submit"
                block
                color="primary"
            >
              Sign Up
            </v-btn>

            <v-divider class="my-4"></v-divider>

            <div class="text-center">
              <router-link :to="{ name: 'login' }">Log in to your account</router-link>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </AppContent>
  </div>
</template>

<script>
import { EMAIL_REGEX, PASSWORD_RULES, PROFESSIONS } from '@/common/util';
import { NoahTextAPI } from '@/common/noahtext-api';
import { M_SET_TOKEN, M_SET_USER } from '@/store/mutations.type';
import AppContent from '@/components/general/AppContent';

export default {
  name: 'Signup',
  components: {AppContent},
  data: () => ({
    valid: false,
    email: '',
    firstName: '',
    lastName: '',
    business: '',
    professionSelection: '',
    profession: '',
    password: '',
    passwordConfirm: '',
    passwordRules: PASSWORD_RULES,
    emailRegex: EMAIL_REGEX,
    professions: PROFESSIONS,
    loading: false,
    error: '',
  }),
  watch: {
    professionSelection() {
      if (this.professionSelection === null) {
        this.professionSelection = '';
      } else if (this.professionSelection.startsWith('Other')) {
        if (this.professions.includes(this.profession)) {
          this.profession = '';
        }
      } else {
        this.profession = this.professionSelection;
      }
    }
  },
  methods: {
    async signup() {
      if (this.loading || !this.valid) return;

      this.error = '';
      this.loading = true;
      try {
        const response = await NoahTextAPI.signup({
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          password: this.password,
          business: this.business,
          profession: this.profession
        });

        this.$store.commit(M_SET_TOKEN, response.token);
        this.$store.commit(M_SET_USER, response.user);
        await this.$router.replace({ name: 'convert' });
      } catch (e) {
        this.error = e;
      }
      this.loading = false;
    }
  }
}
</script>

<style scoped>

</style>
