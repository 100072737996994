import { byteSize } from '@/common/util';

export const chartOptions = {
  maintainAspectRatio: false,
  scales: {
    yAxes: [{
      ticks: {
        beginAtZero: true,
        stepSize: 1,
        maxTicksLimit: 10
      }
    }]
  },
  responsive: true,
  tooltips: {
    mode: 'index',
    intersect: false
  }
};

export const sizeChartOptions = {
  maintainAspectRatio: false,
  scales: {
    yAxes: [{
      ticks: {
        beginAtZero: true,
        stepSize: 1024,
        maxTicksLimit: 10,
        callback: function(value) {
          return byteSize(value);
        }
      }
    }]
  },
  responsive: true,
  tooltips: {
    mode: 'index',
    intersect: false,
    callbacks: {
      label: function(tooltipItem) {
        return byteSize(tooltipItem.yLabel);
      }
    }
  }
};

export const percentChartOptions = {
  maintainAspectRatio: false,
  scales: {
    yAxes: [{
      ticks: {
        beginAtZero: true,
        stepSize: 0.1,
        max: 1,
        callback: function(value) {
          return `${(value * 100).toFixed(2)}%`;
        }
      }
    }]
  },
  responsive: true,
  tooltips: {
    mode: 'index',
    intersect: false,
    callbacks: {
      label: function(tooltipItem) {
        return `${(tooltipItem.yLabel * 100).toFixed(2)}%`;
      }
    }
  }
};
