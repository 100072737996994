<template>
  <div id="review-changes">
    <PageHeader title="Review Changes"></PageHeader>

    <v-menu
        v-model="dateMenu"
        :close-on-content-click="false"
        min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
            v-bind="attrs"
            v-on="on"
            v-model="dateRange"
            filled
            label="Date Range"
            prepend-inner-icon="mdi-calendar"
            readonly
        ></v-text-field>
      </template>

      <v-date-picker
          color="secondary"
          v-model="dates"
          range
      ></v-date-picker>
    </v-menu>

    <v-data-table
        :headers="headers"
        :items="changes"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        :footer-props="{ 'items-per-page-options': [5, 10, 25, 50, 100] }"
        sort-by="created"
        sort-desc
    >
      <template #item.content="{ item }">
        <span class="word">
          <b>
            {{item.word.content[0].toUpperCase() + item.word.content.slice(1)}}
          </b>
        </span>
      </template>

      <template #item.user="{ item }">
        <span>{{item.user.firstName}} {{item.user.lastName}}</span><br>
        <span>{{item.user.email}}</span>
      </template>

      <template #item.syllables="{ item }">
        <span class="word" v-html="syllables(item.word)"></span>
      </template>

      <template #item.longVowels="{ item }">
        <span class="word" v-html="longVowels(item.word)"></span>
      </template>

      <template #item.partOfSpeech="{ item }">
        {{partsOfSpeech[item.word.partOfSpeech]}}
      </template>

      <template #item.created="{ item }">
        {{formatDate(item.created)}}
      </template>

      <template #item.edit="{ item }">
        <v-btn icon color="warning" @click="editWord(item.word)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>

      <template #item.delete="{ item }">
        <v-btn icon color="error" @click="deleteWord(item.word)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <WordEditDialog
        ref="wordEditDialog"
        :initial-word="word"
        @save="refresh()"
        @delete="refresh()"
    />
  </div>
</template>

<script>
import { DEFAULT_WORD, PARTS_OF_SPEECH } from '@/common/util';
import { NoahTextAPI } from '@/common/noahtext-api';
import { add, format } from 'date-fns';
import { cloneDeep } from 'lodash';
import WordEditDialog from '@/components/words/WordEditDialog';
import PageHeader from '@/components/general/PageHeader';

export default {
  name: "ReviewChanges",
  components: {WordEditDialog, PageHeader},
  data: () => ({
    dateMenu: false,
    dates: [format(add(new Date(), { months: -1 }), 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd')],
    changes: [],
    word: DEFAULT_WORD(),
    loading: true,
    total: 0,
    options: {},
    headers: [
      { text: 'Word', value: 'content' },
      { text: 'User', value: 'user' },
      { text: 'Part of Speech', value: 'partOfSpeech' },
      { text: 'Long Vowels', value: 'longVowels', sortable: false },
      { text: 'Syllables', value: 'syllables', sortable: false },
      { text: 'Date', value: 'created' },
      { text: 'Edit', value: 'edit', sortable: false },
      { text: 'Delete', value: 'delete', sortable: false }
    ]
  }),
  watch: {
    options: {
      handler() {
        this.refresh();
      },
      deep: true
    },
    dates() {
      for (let i = 1; i < this.dates.length; i++) {
        if (this.dates[i] < this.dates[i-1]) {
          this.dates.sort();
          break;
        }
      }
      this.refresh(true);
    }
  },
  computed: {
    dateRange() {
      return this.dates.map((date) => {
        const [y, m, d] = date.split('-');
        return `${m}/${d}/${y}`;
      }).join(' - ');
    },
    partsOfSpeech() {
      const parts = {};
      PARTS_OF_SPEECH.forEach((part) => {
        parts[part.value] = part.text;
      });
      return parts;
    }
  },
  methods: {
    async refresh(reset = false) {
      let { sortBy, sortDesc, page, itemsPerPage } = this.options;
      if (reset) page = 1;

      this.loading = true;
      const start = new Date(this.dates[0]);
      const end = add(this.dates.length > 1 ? new Date(this.dates[1]) : start, { days: 1 });
      const response = await NoahTextAPI.get('user-word-edits', {
        params: {
          offset: (page - 1) * itemsPerPage,
          limit: itemsPerPage,
          order: sortBy.length > 0 ? `${sortDesc[0] ? '-' : ''}${sortBy[0]}` : undefined,
          join: 'user,word',
          created: `between(${start.toISOString()},${end.toISOString()})`
        }
      });

      this.total = response.count;
      this.changes = response.results;
      this.loading = false;
    },
    longVowels(word) {
      const data = word.content.split('');
      for (const long of word.longVowels) {
        data[long] = `<b><u>${data[long]}</u></b>`;
      }
      return data.join('');
    },
    syllables(word) {
      const parts = [];
      let cur = 0;
      for (const end of word.syllables) {
        parts.push(word.content.slice(cur, end));
        cur = end;
      }
      return parts.join('<b>-</b>');
    },
    editWord(word) {
      this.word = cloneDeep(word);
      this.$nextTick(() => this.$refs.wordEditDialog.open());
    },
    async deleteWord(word) {
      if (confirm('Are you sure you want to delete this word?')) {
        await NoahTextAPI.deleteWord(word);
        await this.refresh();
      }
    },
    formatDate(date) {
      return format(new Date(date), 'MMM d, yyyy p');
    }
  }
}
</script>

<style scoped>
.word {
  font: 14pt "Courier New", monospace;
}
</style>