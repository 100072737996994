<template>
  <Dialog ref="dialog" title="Upload Documents">
    <v-form @submit.prevent="save" v-model="valid">
      <v-alert
          color="primary"
          border="left"
          dense
          text
      >
        <strong>Please remove links before uploading Word document as links and hyperlink words will be erased!</strong>
      </v-alert>
      <v-alert
          v-if="fontFamily === null"
          type="info"
          border="left"
          dense
          text
      >
        Not all fonts display Noah Text® to its maximum benefit. Please either adjust your document after conversion, or select a recommended font from the conversion settings below. We recommend font size 12-14pt with 1.5 line spacing.
      </v-alert>

      <v-file-input
          class="mb-4"
          v-model="files"
          accept=".docx"
          label="Select Files..."
          multiple
          chips
          counter
          show-size
      ></v-file-input>

      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Conversion Settings
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-select
                :items="modes"
                filled
                class="mb-2"
                item-text="text"
                item-value="value"
                label="Noah Text Conversion Mode"
                v-model="mode"
                hide-details="auto"
            ></v-select>

            <v-select
                :items="fontFamilies"
                filled
                class="mb-2"
                item-text="text"
                item-value="value"
                label="Font"
                v-model="fontFamily"
                hide-details="auto"
            ></v-select>

            <v-checkbox
                v-model="overrideFontSize"
                dense
                hide-details
                label="Change font size"
            ></v-checkbox>
            <v-slider
                v-if="overrideFontSize"
                v-model="fontSize"
                min="4"
                max="64"
                hide-details
                thumb-label="always"
                thumb-color="secondary"
            ></v-slider>

            <v-checkbox
                v-model="overrideLineSpacing"
                dense
                hide-details
                label="Change line spacing"
            ></v-checkbox>
            <v-slider
                v-if="overrideLineSpacing"
                v-model="lineSpacing"
                min="0"
                max="5"
                step="0.05"
                hide-details
                thumb-label="always"
                thumb-color="secondary"
            ></v-slider>

            <v-checkbox
                v-model="preserveFormat"
                dense
                hide-details
                label="Preserve text formatting"
            ></v-checkbox>

            <v-alert
                v-if="preserveFormat"
                class="mt-4"
                type="warning"
                border="left"
                dense
                text
            >
              Preserving text formatting may cause the conversion process to take significantly longer.
            </v-alert>

            <v-checkbox
                v-if="guard('nt:ManageWords')"
                v-model="markUnknown"
                dense
                hide-details
                label="Highlight unrecognized words that may need to be edited"
            ></v-checkbox>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-form>

    <v-divider class="my-4"></v-divider>

    <div class="d-flex mt-2">
      <v-spacer></v-spacer>

      <v-btn text @click="$refs.dialog.close()">
        Cancel
      </v-btn>

      <v-btn
          text
          color="primary"
          :loading="saving"
          @click="save"
      >
        Upload
      </v-btn>
    </div>
  </Dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import { NoahTextAPI } from '@/common/noahtext-api';
import { MODES } from '@/common/util';
import Dialog from '@/components/general/Dialog';

export default {
  name: 'DocumentUploadDialog',
  components: { Dialog },
  data: () => ({
    saving: false,
    valid: false,
    files: [],
    modes: MODES(),
    mode: MODES()[0].value,
    fontFamilies: [
      { text: 'Keep Original Font', value: null },
      { text: 'Courier New', value: 'Courier New' },
      { text: 'Verdana', value: 'Verdana' }
    ],
    fontFamily: 'Courier New',
    overrideFontSize: true,
    fontSize: 14,
    overrideLineSpacing: true,
    lineSpacing: 1.5,
    markUnknown: false,
    preserveFormat: false
  }),
  computed: mapGetters(['guard']),
  watch: {
    overrideFontSize() {
      if (this.overrideFontSize) {
        this.fontSize = this.mode.highlight === 'rime' ? 20 : 14;
      } else {
        this.fontSize = null;
      }
    },
    overrideLineSpacing() {
      if (this.overrideLineSpacing) {
        this.lineSpacing = 1.5;
      } else {
        this.lineSpacing = null;
      }
    }
  },
  methods: {
    open() {
      this.files = [];
      this.$refs.dialog.open();
    },
    close() {
      this.$refs.dialog.close();
    },
    async save() {
      if (!this.valid) return;

      this.saving = true;
      try {
        const urls = (await NoahTextAPI.uploadDocuments({
          files: this.files.map((file) => file.name),
          options: {
            highlight: this.mode.highlight,
            vowel: this.mode.vowel,
            fontFamily: this.fontFamily,
            fontSize: this.fontSize,
            markUnknown: this.markUnknown,
            preserveFormat: this.preserveFormat,
            lineSpacing: this.lineSpacing
          }
        })).urls;
        await Promise.all(this.files.map(async (file, idx) => {
          return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = async () => {
              await axios.put(urls[idx], reader.result, {
                headers: {
                  'Content-Type': 'application/octet-stream'
                }
              });
              resolve();
            }
            reader.readAsArrayBuffer(file);
          });
        }));

        this.$snackbar.success('Successfully uploaded documents!');
        this.$emit('save');
        this.$refs.dialog.close();
      } catch {
        this.$snackbar.error('Error uploading documents.');
      }
      this.saving = false;
    }
  }
}
</script>

<style scoped>

</style>
