<template>
  <Dialog title="How It Works" ref="dialog">
    <div class="sound-key">
      <PageHeader title="Mapping Print to Speech"></PageHeader>
      <p>Noah Text&reg; allows readers to see sound-parts within words, providing a way for striving readers to decode and enunciate words that are too difficult to access. In turn, the improvement in reading accuracy and fluency frees up cognitive resources that readers can devote to comprehending the meaning of the text, enabling them to truly enjoy reading while building their reading skills.</p>

      <PageHeader title="Syllables"></PageHeader>
      <p>A <i>syllable</i> is a unit of pronunciation with only one vowel sound, with or without surrounding consonants. Syllables line up with the way we speak and are an integrated unit of speech and hearing. Teachers often clap out syllables with their students.</p>
      <p>Noah Text® converts words with more than one syllable. For a multiple syllable word, each syllable alternates bold, not bold, bold, etc. For example, the word "syllable" would be broken down into <b>syl</b>la<b>ble</b>, while the word "sound" is not changed at all.</p>

      <PageHeader title="Vowels"></PageHeader>
      <p>A <i>long vowel</i> is a vowel that pronounces its letter name. Here are some examples you will find in Noah Text® along with syllable breaks that are made obvious:</p>

      <v-card>
        <v-card-title>Long (a)</v-card-title>
        <v-card-text class="transcription-syllable text--primary">
          <p>pl<u>a</u>te, p<u>a</u>in, <b>hes</b>i<b>t<u>a</u>te</b>, <b>n<u>a</u></b>tion</p><p>h<u>a</u>ir, r<u>a</u>re, <b>p<u>a</u>r</b>ent, <b>l<u>i</u></b>br<u>a</u>r<b>y</b> </p><p>p<u>a</u>le, f<u>a</u>il, <b>d<u>e</u></b>t<u>a</u>il </p><p>tr<u>a</u>y, <b>al</b>w<u>a</u>ys</p>
        </v-card-text>
      </v-card>

      <v-card>
        <v-card-title>Long (e)</v-card-title>
        <v-card-text class="transcription-syllable text--primary">
          <p>f<u>e</u><u>e</u>t, t<u>e</u>ach, <b>com</b>pl<u>e</u>te</p><p>f<u>e</u><u>e</u>l, d<u>e</u>al, <b>ap</b>p<u>e</u>al</p><p><u>e</u>ar, f<u>e</u>ar, h<u>e</u>re, <b>dis</b>ap<b>p<u>e</u>ar</b>, <b>se</b>v<u>e</u>re</p>
        </v-card-text>
      </v-card>

      <v-card>
        <v-card-title>Long (i)</v-card-title>
        <v-card-text class="transcription-syllable text--primary">
          <p>tr<u>i</u>be, l<u>i</u>ke, n<u>i</u>ght, <b>h<u>i</u>gh</b>l<u>i</u>ght</p><p>f<u>i</u>re, <b>ad</b>m<u>i</u>re, <b>r<u>e</u></b>qu<u>i</u>re</p><p>m<u>i</u>le, p<u>i</u>le, <b>a</b>wh<u>i</u>le, <b>rep</b>t<u>i</u>le</p>
        </v-card-text>
      </v-card>

      <v-card>
        <v-card-title>Long (o)</v-card-title>
        <v-card-text class="transcription-syllable text--primary">
          <p>gl<u>o</u>be, n<u>o</u>se, <b>sup</b>p<u>o</u>se, <b>r<u>e</u></b>m<u>o</u>te</p><p>c<u>o</u>ach, wh<u>o</u>le, c<u>o</u>al, g<u>o</u>al, <b>ap</b>pr<u>o</u>ach</p><p>m<u>o</u>w, bl<u>o</u>wn, <b>win</b>d<u>o</u>w</p>
        </v-card-text>
      </v-card>

      <v-card>
        <v-card-title>Long (u)</v-card-title>
        <v-card-text class="transcription-syllable text--primary">
          <p>h<u>u</u>ge, m<u>u</u>le, <b>f<u>u</u></b>el, <b>per</b>f<u>u</u>me, <b>a</b>m<u>u</u>se</p><p>h<u>u</u>e, <b>ar</b>g<u>u</u>e, <b>tis</b>s<u>u</u>e, bl<u>u</u>e, <b>pol</b>l<u>u</u><b>tion</b></p>
        </v-card-text>
      </v-card>
    </div>
  </Dialog>
</template>

<script>
import PageHeader from '@/components/general/PageHeader';
import Dialog from '@/components/general/Dialog';

export default {
  name: 'SoundKey',
  components: { PageHeader, Dialog },
  methods: {
    open() {
      this.$refs.dialog.open();
    },
    close() {
      this.$refs.dialog.close();
    }
  }
}
</script>

<style scoped>
.sound-key .v-card:not(:last-child) {
  margin-bottom: 16px;
}
</style>
