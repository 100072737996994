<template>
  <Dialog ref="dialog" :title="`${permission.id ? 'Edit' : 'New'} Permission`">
    <v-form @submit.prevent="save" v-model="valid">
      <v-text-field
          autofocus
          label="Name"
          filled
          v-model="permission.name"
          :rules="[v => !!v.trim() || 'Name is required']"
      ></v-text-field>
    </v-form>

    <v-divider class="my-4"></v-divider>

    <div class="d-flex mt-2">
      <v-spacer></v-spacer>

      <v-btn text @click="$refs.dialog.close()">
        Cancel
      </v-btn>

      <v-btn
          text
          color="primary"
          :loading="saving"
          @click="save"
      >
        Save
      </v-btn>
    </div>
  </Dialog>
</template>

<script>
import { NoahTextAPI } from '@/common/noahtext-api';
import Dialog from '@/components/general/Dialog';

export default {
  name: "PermissionEditDialog",
  components: { Dialog },
  props: {
    permission: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    saving: false,
    valid: false
  }),
  methods: {
    open() {
      this.$refs.dialog.open();
    },
    close() {
      this.$refs.dialog.close();
    },
    async save() {
      if (!this.valid) return;

      this.saving = true;
      const method = this.permission.id ? NoahTextAPI.updatePermission : NoahTextAPI.createPermission;
      const updated = await method(this.permission);
      this.$emit('save', updated);
      this.$refs.dialog.close();
      this.saving = false;
    }
  }
}
</script>

<style scoped>

</style>
