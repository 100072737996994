<template>
  <div id="roles">
    <AppContent>
      <PageHeader title="Users"></PageHeader>

      <v-data-table
          :headers="headers"
          :items="users"
          :options.sync="options"
          :server-items-length="total"
          :loading="loading"
          :footer-props="{ 'items-per-page-options': [5, 10, 25, 50, 100] }"
          sort-by="created"
          sort-desc
      >
        <template #item.created="{ item }">
          {{formatDate(item.created)}}
        </template>

        <template #item.updated="{ item }">
          {{formatDate(item.updated)}}
        </template>

        <template #item.roles="{ item }">
          {{item.roles.map((roleId) => roleName(roleId)).join(', ')}}
        </template>

        <template #item.edit="{ item }">
          <v-btn icon color="warning" @click="editUser(item)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>

        <template #item.delete="{ item }">
          <v-btn icon color="error" @click="deleteUser(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>

      <UserEditDialog
          ref="userEditDialog"
          :user="user"
          :roles="roles"
          @save="refresh()"
      ></UserEditDialog>
    </AppContent>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { format } from 'date-fns';
import { NoahTextAPI } from '@/common/noahtext-api';
import { DEFAULT_USER } from '@/common/util';
import AppContent from '@/components/general/AppContent';
import UserEditDialog from '@/components/users/UserEditDialog';
import PageHeader from '@/components/general/PageHeader';

export default {
  name: 'Users',
  components: {AppContent, UserEditDialog, PageHeader },
  data: () => ({
    loading: false,
    users: [],
    roles: [],
    user: DEFAULT_USER(),
    total: 0,
    headers: [
      { text: 'First Name', value: 'firstName' },
      { text: 'Last Name', value: 'lastName' },
      { text: 'Email', value: 'email' },
      { text: 'Business', value: 'business' },
      { text: 'Profession', value: 'profession' },
      { text: 'Created', value: 'created' },
      { text: 'Updated', value: 'updated' },
      { text: 'Roles', value: 'roles', sortable: false },
      { text: 'Edit', value: 'edit', sortable: false },
      { text: 'Delete', value: 'delete', sortable: false }
    ],
    options: {}
  }),
  async mounted() {
    this.roles = (await NoahTextAPI.get('roles', {
      params: {
        limit: -1
      }
    })).results;
  },
  watch: {
    options() {
      this.refresh();
    }
  },
  methods: {
    async refresh() {
      let { sortBy, sortDesc, page, itemsPerPage } = this.options;

      this.loading = true;
      const response = await NoahTextAPI.get('users', {
        params: {
          offset: (page - 1) * itemsPerPage,
          limit: itemsPerPage,
          order: sortBy.length > 0 ? `${sortDesc[0] ? '-' : ''}${sortBy[0]}` : undefined
        }
      });

      this.total = response.count;
      this.users = response.results;
      this.loading = false;
    },
    roleName(roleId) {
      const results = this.roles.filter((r) => r.id === roleId);
      if (results.length > 0) {
        return results[0].name;
      } else {
        return 'Unknown';
      }
    },
    editUser(user) {
      this.user = cloneDeep(user);
      this.$nextTick(() => this.$refs.userEditDialog.open());
    },
    async deleteUser(user) {
      if (confirm('Are you sure you want to delete this user?')) {
        await NoahTextAPI.deleteUser(user);
        await this.refresh();
      }
    },
    formatDate(date) {
      return format(new Date(date), 'MMM d, yyyy');
    }
  }
}
</script>

<style scoped>

</style>