<template>
  <div id="reset-password">
    <AppContent>
      <v-card class="mx-auto" max-width="400">
        <v-toolbar dark color="secondary">
          <v-toolbar-title>Reset Password</v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <v-form v-if="!finished" v-model="valid" @submit.prevent="forgotPassword">
            <v-text-field
                v-model="password"
                :rules="passwordRules"
                type="password"
                filled
                label="New Password"
            ></v-text-field>

            <v-text-field
                v-model="passwordConfirm"
                :rules="[v => v === password || 'Passwords do not match']"
                type="password"
                filled
                label="Confirm Password"
            ></v-text-field>

            <v-btn
                :disabled="!valid"
                :loading="loading"
                type="submit"
                block
                color="primary"
            >
              Reset Password
            </v-btn>
          </v-form>

          <div v-else>
            <v-alert type="success" text dense border="left">
              Your password has successfully been updated!
            </v-alert>

            <v-btn block color="primary" @click="login">
              Log In
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </AppContent>
  </div>
</template>

<script>
import { NoahTextAPI } from '@/common/noahtext-api';
import { logout, PASSWORD_RULES } from '@/common/util';
import { mapGetters } from 'vuex';
import AppContent from '@/components/general/AppContent';

export default {
  name: 'ResetPassword',
  components: {AppContent},
  data: () => ({
    valid: false,
    loading: false,
    finished: false,
    password: '',
    passwordConfirm: '',
    passwordRules: PASSWORD_RULES
  }),
  computed: mapGetters(['user']),
  methods: {
    async forgotPassword() {
      this.loading = true;
      await NoahTextAPI.resetPassword(this.password);
      this.loading = false;
      this.finished = true;
    },
    async login() {
      await logout('login');
    }
  }
}
</script>

<style scoped>

</style>