<template>
  <v-list dense nav>
    <v-list-item class="px-2">
      <v-list-item-avatar>
        <v-img src="@/assets/profile.png"></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{user.firstName}} {{user.lastName}}</v-list-item-title>
        <v-list-item-subtitle>{{user.email}}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-list-item :to="{ name: 'profile' }" link color="secondary">
      <v-list-item-icon>
        <v-icon>mdi-account-box</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Profile</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item :to="{ name: 'settings' }" link color="secondary">
      <v-list-item-icon>
        <v-icon>mdi-cog</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Settings</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item @click="$emit('logout')" link color="secondary">
      <v-list-item-icon>
        <v-icon>mdi-logout</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Logout</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ProfileDropdown',
  computed: mapGetters(['user'])
}
</script>

<style scoped>

</style>