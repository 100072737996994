import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.indigo,
        secondary: colors.green.darken3
      },
      dark: {
        primary: colors.indigo,
        secondary: colors.green.darken3
      }
    }
  }
});
