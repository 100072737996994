<template>
  <div id="settings-general">
    <PageHeader title="General Settings"></PageHeader>

    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>Dark Mode</v-list-item-title>
        <v-list-item-subtitle>Enable or disable dark mode</v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-action>
        <v-switch @change="toggleDarkMode" :input-value="$vuetify.theme.dark"></v-switch>
      </v-list-item-action>
    </v-list-item>
  </div>
</template>

<script>
import PageHeader from '@/components/general/PageHeader';

export default {
  name: 'SettingsGeneral',
  components: {PageHeader},
  methods: {
    toggleDarkMode(value) {
      this.$vuetify.theme.dark = value;
      localStorage.darkMode = JSON.stringify(value);
    }
  }
}
</script>

<style scoped>

</style>