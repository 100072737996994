<template>
  <div id="profile">
    <AppContent>
      <PageHeader title="Profile"></PageHeader>

      <v-form @submit.prevent="save" v-model="valid">
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
                v-model="user.firstName"
                :rules="[v => v && !!v.trim() || 'First Name is required']"
                autofocus
                filled
                label="First Name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
                v-model="user.lastName"
                :rules="[v => v && !!v.trim() || 'Last Name is required']"
                autofocus
                filled
                label="Last Name"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-text-field
            v-model="user.email"
            :rules="[v => v && !!emailRegex.exec(v) || 'Invalid email address']"
            filled
            label="Email"
        ></v-text-field>

        <v-row>
          <v-col cols="12" sm="6">
            <v-select
                v-model="professionSelection"
                filled
                :items="professions"
                label="Profession (Optional)"
                :hide-details="professionSelection.startsWith('Other')"
                clearable
            ></v-select>
            <v-text-field
                v-if="professionSelection.startsWith('Other')"
                v-model="user.profession"
                filled
                label="Profession"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
                v-model="user.business"
                filled
                label="Business Name"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <v-btn
                :disabled="!valid"
                :loading="loading"
                type="submit"
                color="primary"
                block
            >
              Update Profile
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn color="secondary" block @click="$refs.changePasswordDialog.open()">
              Change Password
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

      <ChangePasswordDialog ref="changePasswordDialog"></ChangePasswordDialog>
    </AppContent>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { EMAIL_REGEX, PROFESSIONS } from '@/common/util';
import { NoahTextAPI } from '@/common/noahtext-api';
import ChangePasswordDialog from '@/components/users/ChangePasswordDialog';
import AppContent from '@/components/general/AppContent';
import PageHeader from '@/components/general/PageHeader';

export default {
  name: 'Profile',
  components: {ChangePasswordDialog, AppContent, PageHeader },
  data: () => ({
    valid: false,
    loading: false,
    emailRegex: EMAIL_REGEX,
    professions: PROFESSIONS,
    professionSelection: ''
  }),
  mounted() {
    if (!this.user.profession.trim()) {
      this.professionSelection = '';
    } else if (!this.professions.includes(this.user.profession)) {
      this.professionSelection = this.professions[this.professions.length - 1];
    } else {
      this.professionSelection = this.user.profession;
    }
  },
  watch: {
    professionSelection() {
      if (this.professionSelection === null) {
        this.professionSelection = '';
      } else if (this.professionSelection.startsWith('Other')) {
        if (this.professions.includes(this.user.profession)) {
          this.user.profession = '';
        }
      } else {
        this.user.profession = this.professionSelection;
      }
    }
  },
  computed: mapGetters(['user']),
  methods: {
    async save() {
      this.loading = true;
      await NoahTextAPI.updateProfile(this.user);
      this.loading = false;
    }
  }
}
</script>

<style scoped>

</style>