<template>
  <div id="analytics-logins">
    <PageHeader title="Logins"></PageHeader>

    <UserSearch class="mb-2" v-model="users"></UserSearch>

    <div :class="{ 'd-flex': $vuetify.breakpoint.mdAndUp }">
      <v-select
          :class="{ 'flex-grow-0': true, 'mb-2': true, 'mr-2': $vuetify.breakpoint.mdAndUp }"
          label="Login Type"
          filled
          hide-details
          @input="refresh"
          :items="types"
          v-model="type"
      ></v-select>

      <v-menu
          class="flex-grow-1"
          v-model="dateMenu"
          :close-on-content-click="false"
          min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
              v-bind="attrs"
              v-on="on"
              v-model="dateRange"
              filled
              label="Date Range"
              prepend-inner-icon="mdi-calendar"
              readonly
          ></v-text-field>
        </template>

        <v-date-picker
            color="secondary"
            v-model="dates"
            range
        ></v-date-picker>
      </v-menu>
    </div>

    <v-card class="ma-4">
      <v-card-title>Active Users per Day</v-card-title>
      <v-card-text>
        <LineChart v-if="!loading" :chart-data="activeChartData()" :options="chartOptions"></LineChart>
      </v-card-text>
    </v-card>

    <v-card class="ma-4">
      <v-card-title>Logins per Day</v-card-title>
      <v-card-text>
        <LineChart v-if="!loading" :chart-data="loginChartData()" :options="chartOptions"></LineChart>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import PageHeader from '@/components/general/PageHeader';
import { add, format, startOfDay } from 'date-fns';
import { NoahTextAPI } from '@/common/noahtext-api';
import LineChart from '@/components/analytics/LineChart';
import { chartOptions } from '@/common/charts';
import UserSearch from '@/views/general/UserSearch';

export default {
  name: 'AnalyticsLogins',
  components: {UserSearch, LineChart, PageHeader},
  data: () => ({
    loading: false,
    dateMenu: false,
    dates: [format(add(new Date(), { months: -1 }), 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd')],
    report: [],
    users: [],
    chartOptions,
    types: [
      { text: 'All', value: null },
      { text: 'Login', value: 'login' },
      { text: 'Refresh', value: 'refresh' },
      { text: 'Signup', value: 'signup' }
    ],
    type: null
  }),
  mounted() {
    this.refresh();
  },
  watch: {
    dates() {
      this.refresh();
    },
    users() {
      this.refresh();
    }
  },
  computed: {
    dateRange() {
      return this.dates.map((date) => {
        const [y, m, d] = date.split('-');
        return `${m}/${d}/${y}`;
      }).join(' - ');
    },
    ranges() {
      const ranges = [];
      const dates = this.dates.slice().sort();
      let start = add(startOfDay(new Date(dates[0])), { days: 1 });
      const end = add(new Date(dates[1] || dates[0]), { days: 1 });
      while (start < end) {
        ranges.push({
          start: start.toISOString(),
          end: add(start, { days: 1 }).toISOString()
        });
        start = add(start, { days: 1 });
      }
      return ranges;
    }
  },
  methods: {
    async refresh() {
      this.loading = true;
      this.report = await NoahTextAPI.analyticsUserLogins(this.ranges, this.type || undefined, this.users);
      this.loading = false;
    },
    activeChartData() {
      const data = this.report.map((r) => r.active);
      const labels = [];
      for (const range of this.ranges) {
        labels.push(format(new Date(range.start), 'LLL d'));
      }
      return {
        labels,
        datasets: [
          {
            data,
            label: 'Active Users',
            backgroundColor: this.$vuetify.theme.currentTheme.secondary.base + '88'
          }
        ]
      }
    },
    loginChartData() {
      const totalData = this.report.map((r) => r.total);
      const avgData = this.report.map((r) => r.avg);
      const labels = [];
      for (const range of this.ranges) {
        labels.push(format(new Date(range.start), 'LLL d'));
      }
      return {
        labels,
        datasets: [
          {
            data: totalData,
            label: 'Total Logins',
            backgroundColor: this.$vuetify.theme.currentTheme.secondary.base + '88',
            order: 2
          },
          {
            data: avgData,
            label: 'Average Logins',
            backgroundColor: this.$vuetify.theme.currentTheme.primary.base + '88',
            order: 1
          }
        ]
      }
    }
  }
}
</script>

<style scoped>

</style>