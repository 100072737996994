import store from '../store';
import { M_SET_LOADING, M_SET_TOKEN, M_SET_USER } from '@/store/mutations.type';
import { NoahTextAPI } from '@/common/noahtext-api';

export function authGuard(to, from, next) {
  const guard = (to, from, next) => {
    const basePath = store.getters.authed ? 'convert' : 'home';
    if (to.name !== 'reset-password' && store.getters.resetPassword) {
      // We can only reset password if we have that permission
      return next({ name: 'reset-password' });
    } else if (to.meta.guards && !store.getters.guard(to.meta.guards)) {
      // Go home if we don't have required permissions
      return next({ name: basePath });
    } else {
      // All guards have passed!
      return next();
    }
  };

  // Wait until we've loaded before processing route
  if (store.state.user.loading) {
    let authed = true;
    if (to.query.token) {
      // Our token is a query parameter - only keep for the current session
      store.commit(M_SET_TOKEN, to.query.token);
      localStorage.removeItem('token');
    } else if (localStorage.token) {
      // This is a regular token - behave as normal
      store.commit(M_SET_TOKEN, JSON.parse(localStorage.token));
    } else {
      authed = false;
    }

    if (authed) {
      // We need setTimeout because the Vue app is still undefined
      setTimeout(() => {
        NoahTextAPI.refresh().then((response) => {
          store.commit(M_SET_TOKEN, response.token);
          store.commit(M_SET_USER, response.user);
        });
      }, 0);
    }

    store.commit(M_SET_LOADING, false);
  }

  return guard(to, from, next);
}
