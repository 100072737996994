<template>
  <div id="terms-of-use">
    This is a U.S. Patent No. 11,935,422, registered trademarked, proprietary product. You agree to use the StrongReader™ tool from Noah Text® for noncommercial purposes only. Any and all works in Noah Text® cannot be published, sold, used in advertisements, or used in any commercial way. We are providing access to this tool to help enhance the reading abilities for ALL individuals, as we believe literacy is a fundamental right for ALL.
  </div>
</template>

<script>
export default {
  name: 'TermsOfUse'
}
</script>

<style scoped>
#terms-of-use {
  padding: 16px;
}
</style>
