<template>
  <v-autocomplete
      :items="users"
      :search-input.sync="search"
      :value="value"
      @input="$emit('input', $event)"
      return-object
      item-text="email"
      item-value="id"
      multiple
      cache-items
      filled
      hide-details
      label="Search Users"
      prepend-inner-icon="mdi-magnify"
  >
    <template #selection="{ item, attrs, selected, select }">
      <v-chip
          color="secondary"
          v-bind="attrs"
          :input-value="selected"
          close
          @click="select"
          @click:close="removeUser(item)"
      >
        {{ item.firstName }} {{item.lastName}}
      </v-chip>
    </template>

    <template #item="{ item }">
      <v-list-item-content>
        <v-list-item-title>{{ item.firstName }} {{item.lastName}}</v-list-item-title>
        <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import { NoahTextAPI } from '@/common/noahtext-api';

export default {
  name: 'UserSearch',
  props: {
    value: {
      type: null,
      default: []
    }
  },
  data: () => ({
    loading: false,
    users: [],
    search: '',
    selection: null,
    timeout: null
  }),
  mounted() {
    this.performSearch();
  },
  watch: {
    search() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.performSearch, 500);
    }
  },
  methods: {
    async performSearch() {
      this.loading = true;
      this.users = await NoahTextAPI.get('users/search', {
        params: { search: this.search }
      });
      this.loading = false;
    },
    removeUser(user) {
      this.$emit('input', this.value.filter(other => other.id !== user.id));
    }
  }
}
</script>

<style scoped>

</style>