<template>
  <div id="convert">
    <AppContent>
      <PageHeader title="Convert Text">
        <v-switch
            v-if="guard('nt:ManageWords')"
            class="ma-0"
            v-model="convertUnknown"
            label="Convert Unrecognized Words"
            hide-details
        ></v-switch>
      </PageHeader>

      <v-alert
          v-if="!rimeErrorClosed && rimeError"
          type="warning"
          border="left"
          dense
          dismissible
          text
          @input="rimeErrorClosed = !$event || rimeErrorClosed"
      >
        We recommend rimes for single syllable words only. The text you converted has one or more words with multiple syllables.
      </v-alert>

      <v-select
          :items="modes"
          filled
          class="mb-2"
          item-text="text"
          item-value="value"
          label="Noah Text Conversion Mode"
          v-model="mode"
          hide-details
      ></v-select>

      <v-textarea
          class="mb-2"
          v-model="text"
          filled
          clearable
          :counter="maxInput"
          label="Enter text to convert to Noah Text®"
          autofocus
      ></v-textarea>

      <v-row>
        <v-col cols="12" sm="6">
          <v-btn
              block
              color="primary"
              :loading="loading"
              :disabled="!text || !text.trim()"
              @click="convert"
          >
            <v-icon>mdi-format-text-rotation-none</v-icon>
            Convert
          </v-btn>
        </v-col>
        <v-col cols="12" sm="3">
          <v-btn
              block
              color="secondary"
              :disabled="transcript.length === 0"
              @click="copy"
          >
            <v-icon>mdi-content-copy</v-icon>
            <span v-if="copied">Copied!</span>
            <span v-else>Copy</span>
          </v-btn>
        </v-col>
        <v-col cols="12" sm="3">
          <v-btn
              block
              color="secondary"
              :disabled="transcript.length === 0"
              @click="print"
          >
            <v-icon>mdi-printer</v-icon>
            <span>Print</span>
          </v-btn>
        </v-col>
      </v-row>

      <v-card v-if="!loading && transcript.length > 0" class="mt-4">
        <v-card-title v-if="improvement !== null">StrongReader has improved readability by altering {{(improvement * 100).toFixed(0)}}% of the words in this text.</v-card-title>

        <v-divider></v-divider>

        <div
            :class="{ 'transcription-syllable': mode.highlight === 'syllable', 'transcription-rime': mode.highlight === 'rime', 'transcription': true }"
            ref="transcription"
        >
          <p v-for="(paragraph, pidx) of transcript" class="paragraph" :key="pidx">
            <Word
                v-for="(word, widx) in paragraph"
                :key="widx"
                :word="word"
                :mode="mode"
                @click="editWord(word)"
            />
          </p>
        </div>
      </v-card>

      <WordEditDialog
          ref="wordEditDialog"
          :initial-word="word"
          @save="updateWords($event)"
          @delete="deleteWord($event)"
      />
    </AppContent>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import { NoahTextAPI } from '@/common/noahtext-api';
import { DEFAULT_WORD, MODES, printElement } from '@/common/util';
import AppContent from '@/components/general/AppContent';
import PageHeader from '@/components/general/PageHeader';
import WordEditDialog from '@/components/words/WordEditDialog';
import Word from '@/components/words/Word';

export default {
  name: 'Convert',
  components: {AppContent, PageHeader, WordEditDialog, Word },
  data: () => ({
    text: null,
    loading: false,
    rimeError: false,
    rimeErrorClosed: false,
    transcript: [],
    improvement: null,
    word: DEFAULT_WORD(),
    defaultModel: DEFAULT_WORD,
    modes: MODES(),
    mode: MODES()[0].value,
    copied: false,
    copiedTimeout: null,
    maxInput: 100000,
    convertUnknown: false
  }),
  created() {
    if (localStorage.convertUnknown) {
      this.convertUnknown = JSON.parse(localStorage.convertUnknown);
    }
  },
  watch: {
    transcript() {
      this.updateRimeError();
    },
    mode() {
      this.updateRimeError();
    },
    convertUnknown() {
      localStorage.convertUnknown = JSON.stringify(this.convertUnknown);
    }
  },
  computed: mapGetters(['guard']),
  methods: {
    async convert() {
      this.loading = true;
      const response = await NoahTextAPI.convert(
          this.text.slice(0, this.maxInput).split('\n'),
          this.guard('nt:ManageWords') && this.convertUnknown
      );
      this.improvement = response.improvement;
      this.transcript = response.transcript;
      this.loading = false;
    },
    editWord(word) {
      this.word = cloneDeep(word);
      this.$nextTick(() => this.$refs.wordEditDialog.open());
    },
    updateWords(updated) {
      this.transcript = this.transcript.map((paragraph) => {
        return paragraph.map((word) => {
          // We've created a new word
          if (!word.id && word.content.toLowerCase() === this.word.content.toLowerCase()) {
            return {
              ...updated[0],
              content: word.content
            };
          }
          // The word has been updated
          for (const update of updated) {
            if (update.id === word.id) return {
              ...update,
              content: word.content
            };
          }
          // No change
          return word;
        });
      });
    },
    deleteWord(deleted) {
      this.transcript = this.transcript.map((paragraph) => {
        return paragraph.map((word) => {
          if (deleted.id === word.id) {
            return {
              ...cloneDeep(word),
              id: undefined
            };
          }
          return word;
        });
      });
    },
    updateRimeError() {
      if (this.mode.highlight === 'rime') {
        this.rimeError = this.transcript.filter((paragraph) => {
          return paragraph.filter((word) => {
            return word.type === 'word' && word.syllables.length > 1;
          }).length > 0;
        }).length > 0;
      } else {
        this.rimeError = false;
      }
    },
    copy() {
      // Clear the cooldown on the previous message
      clearTimeout(this.copied);

      // Manually copy the contents of the transcription
      const range = document.createRange();
      range.selectNode(this.$refs.transcription);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand('copy');
      window.getSelection().removeAllRanges();

      // Display a "copied!" message
      this.copied = true;
      this.copiedTimeout = setTimeout(() => this.copied = false, 2500);
    },
    print() {
      const element = document.getElementsByClassName('transcription')[0];
      printElement(element);
    }
  }
}
</script>

<style scoped>
.paragraph {
  display: flex;
  flex-flow: wrap;
}
</style>