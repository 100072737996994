var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"review"}},[_c('v-select',{staticClass:"mb-2",attrs:{"label":"Show Words","filled":"","hide-details":"","items":_vm.validFilters},on:{"input":_vm.updateValidity},model:{value:(_vm.validFilter),callback:function ($$v) {_vm.validFilter=$$v},expression:"validFilter"}}),_c('div',{class:{ 'd-flex': _vm.$vuetify.breakpoint.mdAndUp }},[_c('v-select',{class:{ 'flex-grow-0': true, 'mb-2': true, 'mr-2': _vm.$vuetify.breakpoint.mdAndUp },attrs:{"label":"Search Comparison","filled":"","hide-details":"","items":_vm.searchComps},on:{"input":_vm.updateComparison},model:{value:(_vm.searchComp),callback:function ($$v) {_vm.searchComp=$$v},expression:"searchComp"}}),_c('v-text-field',{staticClass:"flex-grow-1",attrs:{"label":"Search Dictionary","hide-details":"","clearable":"","filled":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.words,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"item-class":_vm.rowClass,"footer-props":{ 'items-per-page-options': [5, 10, 25, 50, 100] },"sort-by":_vm.review ? 'hits' : 'content',"sort-desc":_vm.review},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.content",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"word"},[_c('b',[_vm._v(" "+_vm._s(item.content ? item.content[0].toUpperCase() + item.content.slice(1) : '')+" ")])])]}},{key:"item.syllables",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"word",domProps:{"innerHTML":_vm._s(_vm.syllables(item))}})]}},{key:"item.longVowels",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"word",domProps:{"innerHTML":_vm._s(_vm.longVowels(item))}})]}},{key:"item.partOfSpeech",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.partsOfSpeech[item.partOfSpeech])+" ")]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"warning"},on:{"click":function($event){return _vm.editWord(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}},{key:"item.review",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"success"},on:{"click":function($event){return _vm.reviewWord(item)}}},[_c('v-icon',[_vm._v("mdi-clipboard-search-outline")])],1)]}},{key:"item.valid",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"hide-details":""},on:{"change":function($event){return _vm.validifyWord(item)}},model:{value:(item.valid),callback:function ($$v) {_vm.$set(item, "valid", $$v)},expression:"item.valid"}})]}}])}),_c('WordEditDialog',{ref:"wordEditDialog",attrs:{"initial-word":_vm.word},on:{"save":function($event){return _vm.refresh()},"delete":function($event){return _vm.refresh()}}}),_c('Dialog',{ref:"addWordDialog",attrs:{"title":"Add Word"}},[_c('v-text-field',{attrs:{"filled":"","hide-details":"","label":"New Word","autofocus":""},model:{value:(_vm.addWordContent),callback:function ($$v) {_vm.addWordContent=$$v},expression:"addWordContent"}}),_c('v-divider',{staticClass:"my-4"}),_c('div',{staticClass:"d-flex"},[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$refs.addWordDialog.close()}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.finishAddWord}},[_vm._v(" Add ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }