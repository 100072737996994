<template>
  <div id="settings">
    <v-tabs v-model="tab">
      <v-tab key="general">General</v-tab>
      <v-tab key="developer">Developer</v-tab>
    </v-tabs>

    <AppContent>
      <v-tabs-items v-model="tab">
        <v-tab-item key="general">
          <SettingsGeneral></SettingsGeneral>
        </v-tab-item>
        <v-tab-item key="developer">
          <SettingsDeveloper></SettingsDeveloper>
        </v-tab-item>
      </v-tabs-items>
    </AppContent>
  </div>
</template>

<script>
import AppContent from '@/components/general/AppContent';
import SettingsDeveloper from '@/views/settings/SettingsDeveloper';
import SettingsGeneral from '@/views/settings/SettingsGeneral';

export default {
  name: 'Settings',
  components: {SettingsGeneral, SettingsDeveloper, AppContent},
  data: () => ({
    tab: 'general'
  })
}
</script>

<style scoped>

</style>