<template>
  <div id="settings-developer">
    <PageHeader title="Developer Settings">
      <v-btn color="primary" @click="editToken(defaultModel())">
        <v-icon class="mr-2">mdi-plus</v-icon>
        Generate Token
      </v-btn>
    </PageHeader>

    <p class="mb-2">See our <a target="_blank" href="https://api.noahtext.com/api-docs/">API documentation</a> for information about converting text to Noah Text. HTML conversions and raw conversions are currently supported.</p>
    <p>When generating a token, set the <code>Authorization</code> header to the case-sensitive value <code>Token {your token}</code> to authenticate API calls. The token does not expire, and it has the same permissions as your account. If your token is compromised, regenerate it from this page and update usages as soon as possible.</p>

    <v-data-table
        :headers="headers"
        :items="tokens"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        :footer-props="{ 'items-per-page-options': [5, 10, 25, 50, 100] }"
        sort-by="created"
        sort-desc
    >
      <template #item.created="{ item }">
        {{formatDate(item.created)}}
      </template>

      <template #item.regenerate="{ item }">
        <v-btn icon color="primary" @click="regenerate(item)">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </template>

      <template #item.edit="{ item }">
        <v-btn icon color="warning" @click="editToken(item)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>

      <template #item.delete="{ item }">
        <v-btn icon color="error" @click="deleteToken(item)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <TokenEditDialog
        ref="tokenEditDialog"
        :token="token"
        @save="refresh"
    ></TokenEditDialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { format } from 'date-fns';
import PageHeader from '@/components/general/PageHeader';
import { DEFAULT_TOKEN } from '@/common/util';
import { NoahTextAPI } from '@/common/noahtext-api';
import { cloneDeep } from 'lodash';
import TokenEditDialog from '@/components/tokens/TokenEditDialog';

export default {
  name: 'SettingsDeveloper',
  components: {TokenEditDialog, PageHeader},
  data: () => ({
    loading: false,
    tokens: [],
    token: DEFAULT_TOKEN(),
    defaultModel: DEFAULT_TOKEN,
    total: 0,
    headers: [
      { text: 'Description', value: 'description' },
      { text: 'Value', value: 'value' },
      { text: 'Created', value: 'created' },
      { text: 'Regenerate', value: 'regenerate', sortable: false },
      { text: 'Edit', value: 'edit', sortable: false },
      { text: 'Delete', value: 'delete', sortable: false }
    ],
    options: {}
  }),
  watch: {
    options() {
      this.refresh();
    }
  },
  computed: mapGetters(['user']),
  methods: {
    async refresh() {
      let { sortBy, sortDesc, page, itemsPerPage } = this.options;

      this.loading = true;
      const response = await NoahTextAPI.get('tokens', {
        params: {
          offset: (page - 1) * itemsPerPage,
          limit: itemsPerPage,
          order: sortBy.length > 0 ? `${sortDesc[0] ? '-' : ''}${sortBy[0]}` : undefined,
          userId: this.user.id
        }
      });

      this.total = response.count;
      this.tokens = response.results;
      this.loading = false;
    },
    editToken(token) {
      this.token = cloneDeep(token);
      this.$nextTick(() => this.$refs.tokenEditDialog.open());
    },
    async deleteToken(token) {
      if (confirm('Are you sure you want to delete this token?')) {
        await NoahTextAPI.deleteToken(token);
        await this.refresh();
      }
    },
    async regenerate(token) {
      if (confirm('Are you sure you want to regenerate this token? If so, be sure to update all usages to the new token.')) {
        await NoahTextAPI.regenerateToken(token);
        await this.refresh();
      }
    },
    formatDate(date) {
      return format(new Date(date), 'MMM d, yyyy p');
    }
  }
}
</script>

<style scoped>

</style>