var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"documents"}},[_c('AppContent',[_c('PageHeader',{attrs:{"title":"Convert Documents"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$refs.uploadDocumentDialog.open()}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-upload")]),_vm._v(" Upload Documents ")],1)],1),_c('v-alert',{attrs:{"color":"primary","border":"left","dense":"","text":""}},[_c('p',{staticClass:"mb-0"},[_vm._v("StrongReader supports conversions for modern Microsoft Word documents (.docx).")])]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.documents,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"footer-props":{ 'items-per-page-options': [5, 10, 25, 50, 100] },"sort-by":"created","sort-desc":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[(item.error)?_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-alert-circle")]):(item.progress < 1)?_c('v-progress-circular',{attrs:{"value":item.progress * 100,"size":"24","color":"secondary"}}):_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.downloadDocument(item)}}},[_c('v-icon',[_vm._v("mdi-download")])],1)],1)]}}],null,true)},[(item.error)?_c('div',[_vm._v("Error: "+_vm._s(item.error))]):(item.progress < 1)?_c('div',[_vm._v("Processing...")]):_c('div',[_vm._v("Download")])])]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [(!item.error && item.progress === 1)?_c('span',[_vm._v(" "+_vm._s(_vm.byteSize(item.size))+" ")]):_c('span',[_vm._v("-")])]}},{key:"item.retry",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"disabled":!item.error && item.progress < 1,"icon":"","color":"primary"},on:{"click":function($event){return _vm.convertDocument(item)}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"warning"},on:{"click":function($event){return _vm.editDocument(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.deleteDocument(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])}),_c('UploadDocumentDialog',{ref:"uploadDocumentDialog",on:{"save":_vm.refresh}}),_c('DocumentEditDialog',{ref:"editDocumentDialog",attrs:{"document":_vm.document},on:{"save":_vm.refresh}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }