<template>
  <div id="faq">
    <AppContent>
      <PageHeader title="Frequently Asked Questions">
        <v-btn v-if="guard('nt:ManageFAQ')" class="mr-2" color="primary" @click="editCategory(defaultCategory())">
          <v-icon class="mr-2">mdi-plus</v-icon>
          Create Category
        </v-btn>
        <v-btn v-if="guard('nt:ManageFAQ')" color="primary" @click="editResult(defaultResult())">
          <v-icon class="mr-2">mdi-plus</v-icon>
          Create Result
        </v-btn>
      </PageHeader>

      <v-text-field
          v-model="search"
          filled
          label="Search Questions"
      ></v-text-field>

      <v-expansion-panels v-model="panel" multiple>
        <v-expansion-panel v-for="category in categories" :key="category.id">
          <v-expansion-panel-header>
            <div class="d-flex align-center">
              <h3>{{category.name}}</h3>
              <div class="flex-grow-1"></div>
              <div v-if="guard('nt:ManageFAQ')">
                <v-btn icon color="warning" @click.stop="editCategory(category)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon color="error" @click.stop="deleteCategory(category)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list>
              <v-divider v-if="categoryResults(category).length > 0"></v-divider>

              <template v-for="result in categoryResults(category)">
                <v-list-item @click="viewResult(result)" link :key="result.id">
                  <v-list-item-icon>
                    <v-icon color="secondary">mdi-help-circle</v-icon>
                  </v-list-item-icon>

                  <v-list-item-title>
                    <div class="d-flex align-center">
                      {{result.question}}
                      <div class="flex-grow-1"></div>
                      <div v-if="guard('nt:ManageFAQ')">
                        <v-btn icon color="warning" @click.stop="editResult(result)">
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn icon color="error" @click.stop="deleteResult(result)">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </v-list-item-title>
                </v-list-item>

                <v-divider :key="`${result.id}-divider`"></v-divider>
              </template>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <FAQCategoryEditDialog ref="categoryEditDialog" :category="category" @save="refresh"></FAQCategoryEditDialog>
      <FAQResultEditDialog ref="resultEditDialog" :categories="categories" :result="result" @save="refresh"></FAQResultEditDialog>
      <FAQResultViewDialog ref="resultViewDialog" :result="result"></FAQResultViewDialog>
    </AppContent>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { mapGetters } from 'vuex';
import { NoahTextAPI } from '@/common/noahtext-api';
import AppContent from '@/components/general/AppContent';
import PageHeader from '@/components/general/PageHeader';
import FAQCategoryEditDialog from '@/components/faq/FAQCategoryEditDialog';
import FAQResultEditDialog from '@/components/faq/FAQResultEditDialog';
import { DEFAULT_FAQ_CATEGORY, DEFAULT_FAQ_RESULT } from '@/common/util';
import FAQResultViewDialog from '@/components/faq/FAQResultViewDialog';

export default {
  name: "FAQ",
  components: {FAQResultViewDialog, FAQResultEditDialog, FAQCategoryEditDialog, PageHeader, AppContent},
  data: () => ({
    search: '',
    category: DEFAULT_FAQ_CATEGORY(),
    result: DEFAULT_FAQ_RESULT(),
    defaultCategory: DEFAULT_FAQ_CATEGORY,
    defaultResult: DEFAULT_FAQ_RESULT,
    categories: [],
    results: [],
    filteredResults: [],
    panel: []
  }),
  async created() {
    await this.refresh();
  },
  watch: {
    search() {
      if (this.search.trim()) {
        this.filteredResults = this.results.filter((result) => {
          return result.question.toLowerCase().includes(this.search.trim().toLowerCase());
        });
      } else {
        this.filteredResults = this.results;
      }
    }
  },
  computed: mapGetters(['guard']),
  methods: {
    async refresh() {
      this.categories = (await NoahTextAPI.get('faq/categories', {
        params: { limit: -1, order: 'name' }
      })).results;
      this.results = (await NoahTextAPI.get('faq/results', {
        params: { limit: -1, order: 'question' }
      })).results;
      this.filteredResults = this.results;
      this.panel = this.categories.map((category, idx) => idx);
    },
    editCategory(category) {
      this.category = cloneDeep(category);
      this.$nextTick(() => this.$refs.categoryEditDialog.open());
    },
    editResult(result) {
      this.result = cloneDeep(result);
      this.$nextTick(() => this.$refs.resultEditDialog.open());
    },
    categoryResults(category) {
      return this.filteredResults.filter((result) => result.categoryId === category.id);
    },
    viewResult(result) {
      this.result = cloneDeep(result);
      this.$nextTick(() => this.$refs.resultViewDialog.open());
    },
    async deleteCategory(category) {
      if (confirm('Are you sure you want to delete this category?')) {
        await NoahTextAPI.deleteFAQCategory(category);
        await this.refresh();
      }
    },
    async deleteResult(result) {
      if (confirm('Are you sure you want to delete this result?')) {
        await NoahTextAPI.deleteFAQResult(result);
        await this.refresh();
      }
    }
  }
}
</script>

<style scoped>

</style>