import store from '@/store';
import router from '@/router';
import app from '@/main';
import { M_SET_TOKEN } from '@/store/mutations.type';

export function MODES() {
  let modes = [
    {
      text: 'Syllables + Long Vowels',
      description: 'Chapter Books (Basic)',
      value: {
        vowel: true,
        highlight: 'syllable'
      }
    },
    {
      text: 'Syllables Only',
      description: 'Chapter Books (Advanced)',
      value: {
        vowel: false,
        highlight: 'syllable'
      }
    },
    {
      text: 'Long Vowels Only',
      description: 'Chapter Books (Alternate)',
      value: {
        vowel: true,
        highlight: 'none'
      }
    }
  ];

  if (store.getters.guard('nt:ManageWords')) {
    modes = modes.concat([
      {
        text: 'Rimes + Long Vowels',
        description: 'Early Readers (Basic)',
        value: {
          vowel: true,
          highlight: 'rime'
        }
      },
      {
        text: 'Rimes Only',
        description: 'Early Readers (Advanced)',
        value: {
          vowel: false,
          highlight: 'rime'
        }
      }
    ]);
  }

  return modes;
}

export const SEARCH_COMPARISONS = [
  {
    text: 'Matches',
    value: {
      key: 'match',
      func: (search) => search
    }
  },
  {
    text: 'Starts With',
    value: {
      key: 'start',
      func: (search) => `like(${search}%)`
    }
  },
  {
    text: 'Ends With',
    value: {
      key: 'end',
      func: (search) => `like(%${search})`
    }
  },
  {
    text: 'Contains',
    value: {
      key: 'contain',
      func: (search) => `like(%${search}%)`
    }
  }
];

export const PROFESSIONS = [
    'Preschool Teacher',
    'Elementary Teacher - lower grades',
    'Elementary Teacher - upper grades',
    'Middle School Teacher',
    'High School Teacher',
    'Adult Education Teacher',
    'Literacy Specialist',
    'Special Education Teacher',
    'ESL/ELL Teacher',
    'College Professor',
    'Researcher',
    'Publisher/Author',
    'Ed Tech Professional',
    'Parent',
    'Student',
    'Other (Enter Below)'
];

export const PARTS_OF_SPEECH = [
  { text: 'Adjective', value: 'adjective' },
  { text: 'Adverb', value: 'adverb' },
  { text: 'Conjunction', value: 'conjunction' },
  { text: 'Noun', value: 'noun' },
  { text: 'Preposition', value: 'preposition' },
  { text: 'Pronoun', value: 'pronoun' },
  { text: 'Verb', value: 'verb' },
];

export const PASSWORD_RULES = [
    v => !!/[a-z]/.exec(v) || 'Must contain at least one lowercase letter',
    v => !!/[A-Z]/.exec(v) || 'Must contain at least one uppercase letter',
    v => !!/\d/.exec(v) || 'Must contain at least one number',
    v => !!/[^a-zA-Z\d]/.exec(v) || 'Must contain at least one special character',
    v => v.length >= 8 || 'Must be at least 8 characters'
];

// eslint-disable-next-line no-control-regex
export const EMAIL_REGEX = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export function DEFAULT_TOKEN() {
  return {
    description: '',
    value: ''
  };
}

export function DEFAULT_PERMISSION() {
  return {
    name: ''
  };
}

export function DEFAULT_ROLE() {
  return {
    name: '',
    permissions: []
  };
}

export function DEFAULT_WORD() {
  return {
    content: '',
    partOfSpeech: 'noun',
    phonemes: [],
    review: [],
    syllables: [],
    longVowels: []
  };
}

export function DEFAULT_USER() {
  return {
    name: '',
    email: '',
    password: '',
    roles: []
  };
}

export function DEFAULT_DOCUMENT() {
  return {
    name: '',
    processing: true
  };
}

export function DEFAULT_FAQ_CATEGORY() {
  return {
    name: ''
  };
}

export function DEFAULT_FAQ_RESULT() {
  return {
    question: '',
    answer: ''
  };
}

export function base64UrlDecode(data) {
  // Replace non-url compatible chars with base64 standard chars
  data = data
      .replace(/-/g, '+')
      .replace(/_/g, '/');

  // Pad out with standard base64 required padding characters
  const pad = data.length % 4;
  if(pad) {
    data += new Array(5 - pad).join('=');
  }

  return atob(data);
}

export function printElement(element) {
  const printWindow = window.open('', 'PRINT');
  printWindow.document.write(`\
<html>
  <head>
    <title>${document.title}</title>
    <style>
      p {
        font: 14pt "Courier New", monospace;
        line-height: 1.5;
        white-space: pre-wrap;
      }
    </style>
  </head>
  <body>
    ${element.innerHTML}
  </body>
</html>`);
  printWindow.document.close();

  printWindow.focus();
  printWindow.print();
  printWindow.close();
}

export async function logout(routeName = 'home') {
  app.$gtag.event('account-logout', {
    event_category: 'account'
  });
  store.commit(M_SET_TOKEN, null);
  if (router.currentRoute.name !== routeName) {
    await router.replace({ name: routeName });
  }
}

export function byteSize(size) {
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
  let sizeIdx = 0;
  while (size > 1024) {
    size /= 1024;
    sizeIdx += 1;
  }
  return `${size.toFixed(2)} ${sizes[sizeIdx]}`;
}
