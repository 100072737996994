<template>
  <Dialog ref="dialog" :title="`${result.id ? 'Edit' : 'New'} FAQ Result`">
    <v-form @submit.prevent="save" v-model="valid">
      <v-select
          class="mb-2"
          :items="categories"
          filled
          label="Category"
          item-text="name"
          item-value="id"
          v-model="result.categoryId"
          hide-details
      ></v-select>

      <v-text-field
          autofocus
          label="Question"
          filled
          v-model="result.question"
          :rules="[v => !!v.trim() || 'Question is required']"
      ></v-text-field>

      <tiptap-vuetify v-model="result.answer" :extensions="extensions"></tiptap-vuetify>
    </v-form>

    <v-divider class="my-4"></v-divider>

    <div class="d-flex mt-2">
      <v-spacer></v-spacer>

      <v-btn text @click="$refs.dialog.close()">
        Cancel
      </v-btn>

      <v-btn
          text
          color="primary"
          :loading="saving"
          @click="save"
      >
        Save
      </v-btn>
    </div>
  </Dialog>
</template>

<script>
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify';
import { NoahTextAPI } from '@/common/noahtext-api';
import Dialog from '@/components/general/Dialog';

export default {
  name: 'FAQResultEditDialog',
  components: { Dialog, TiptapVuetify },
  props: {
    result: {
      type: Object,
      required: true
    },
    categories: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    saving: false,
    valid: false,
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [Heading, {
        options: {
          levels: [1, 2, 3]
        }
      }],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak
    ]
  }),
  methods: {
    open() {
      if (!this.result.categoryId && this.categories.length > 0) {
        this.result.categoryId = this.categories[0].id;
      }
      this.$refs.dialog.open();
    },
    close() {
      this.$refs.dialog.close();
    },
    async save() {
      if (!this.valid) return;

      this.saving = true;
      const method = this.result.id ? NoahTextAPI.updateFAQResult : NoahTextAPI.createFAQResult;
      const updated = await method(this.result);
      this.$emit('save', updated);
      this.$refs.dialog.close();
      this.saving = false;
    }
  }
}
</script>

<style scoped>

</style>
