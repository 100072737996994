<template>
  <Dialog ref="dialog" title="Change Password">
    <v-form @submit.prevent="save" v-model="valid">
      <v-alert v-if="error" type="error" border="left" text dense>
        {{error}}
      </v-alert>

      <v-text-field
          v-model="oldPassword"
          autofocus
          type="password"
          label="Old Password"
          filled
          :rules="[v => !!v.trim() || 'Old Password is required']"
      ></v-text-field>

      <v-text-field
          v-model="password"
          :rules="passwordRules"
          type="password"
          filled
          label="New Password"
      ></v-text-field>

      <v-text-field
          v-model="passwordConfirm"
          :rules="[v => v === password || 'Passwords do not match']"
          type="password"
          filled
          label="Confirm New Password"
      ></v-text-field>

      <v-divider class="my-4"></v-divider>

      <div class="d-flex mt-2">
        <v-spacer></v-spacer>

        <v-btn text @click="$refs.dialog.close()">
          Cancel
        </v-btn>

        <v-btn
            text
            type="submit"
            color="primary"
            :disabled="!valid"
            :loading="saving"
            @click="save"
        >
          Change Password
        </v-btn>
      </div>
    </v-form>
  </Dialog>
</template>

<script>
import { NoahTextAPI } from '@/common/noahtext-api';
import { PASSWORD_RULES } from '@/common/util';
import Dialog from '@/components/general/Dialog';

export default {
  name: "ChangePasswordDialog",
  components: { Dialog },
  data: () => ({
    saving: false,
    valid: false,
    error: '',
    oldPassword: '',
    password: '',
    passwordConfirm: '',
    passwordRules: PASSWORD_RULES
  }),
  methods: {
    open() {
      Object.assign(this.$data, this.$options.data.apply(this));
      this.$refs.dialog.open();
    },
    close() {
      this.$refs.dialog.close();
    },
    async save() {
      if (!this.valid) return;

      this.saving = true;
      try {
        await NoahTextAPI.changePassword(this.oldPassword, this.password);
        this.$snackbar.success('Successfully changed password!');
        this.$refs.dialog.close();
      } catch (e) {
        this.error = e;
      }
      this.saving = false;
    }
  }
}
</script>

<style scoped>

</style>
