<template>
  <div id="review-words">
    <PageHeader title="Review Words"></PageHeader>

    <WordTable review></WordTable>
  </div>
</template>

<script>
import PageHeader from '@/components/general/PageHeader';
import WordTable from '@/components/words/WordTable';

export default {
  name: 'ReviewWords',
  components: { PageHeader, WordTable },
}
</script>

<style scoped>

</style>
