<template>
  <div id="permissions">
    <AppContent>
      <PageHeader title="Permissions">
        <v-btn color="primary" @click="editPermission(defaultModel())">
          <v-icon class="mr-2">mdi-plus</v-icon>
          Add Permission
        </v-btn>
      </PageHeader>

      <v-data-table
          :headers="headers"
          :items="permissions"
          :options.sync="options"
          :server-items-length="total"
          :loading="loading"
          :footer-props="{ 'items-per-page-options': [5, 10, 25, 50, 100] }"
          sort-by="name"
      >
        <template #item.edit="{ item }">
          <v-btn icon color="warning" @click="editPermission(item)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>

        <template #item.delete="{ item }">
          <v-btn icon color="error" @click="deletePermission(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>

      <PermissionEditDialog
          ref="permissionEditDialog"
          :permission="permission"
          @save="refresh"
      ></PermissionEditDialog>
    </AppContent>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { NoahTextAPI } from '@/common/noahtext-api';
import { DEFAULT_PERMISSION } from '@/common/util';
import PageHeader from '@/components/general/PageHeader';
import PermissionEditDialog from '@/components/permissions/PermissionEditDialog';
import AppContent from '@/components/general/AppContent';

export default {
  name: 'Permissions',
  components: {AppContent, PermissionEditDialog, PageHeader},
  data: () => ({
    loading: false,
    permissions: [],
    permission: DEFAULT_PERMISSION(),
    defaultModel: DEFAULT_PERMISSION,
    total: 0,
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Edit', value: 'edit', sortable: false },
      { text: 'Delete', value: 'delete', sortable: false }
    ],
    options: {}
  }),
  watch: {
    options() {
      this.refresh();
    }
  },
  methods: {
    async refresh() {
      let { sortBy, sortDesc, page, itemsPerPage } = this.options;

      this.loading = true;
      const response = await NoahTextAPI.get('permissions', {
        params: {
          offset: (page - 1) * itemsPerPage,
          limit: itemsPerPage,
          order: sortBy.length > 0 ? `${sortDesc[0] ? '-' : ''}${sortBy[0]}` : undefined
        }
      });

      this.total = response.count;
      this.permissions = response.results;
      this.loading = false;
    },
    editPermission(permission) {
      this.permission = cloneDeep(permission);
      this.$nextTick(() => this.$refs.permissionEditDialog.open());
    },
    async deletePermission(permission) {
      if (confirm('Are you sure you want to delete this permission?')) {
        await NoahTextAPI.deletePermission(permission);
        await this.refresh();
      }
    }
  }
}
</script>

<style scoped>

</style>