<template>
  <v-navigation-drawer
      app
      mobile-breakpoint="md"
      @input="$emit('update:drawer', $event)"
      :value="drawer"
      :clipped="$vuetify.breakpoint.mdAndUp"
      :permanent="$vuetify.breakpoint.mdAndUp"
      :temporary="$vuetify.breakpoint.smAndDown"
  >
    <v-list dense nav>
      <v-list-item
          :to="{ name: 'convert' }"
          link
          color="secondary"
      >
        <v-list-item-icon>
          <v-icon>mdi-format-text-rotation-none</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Convert Text</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
          :to="{ name: 'documents' }"
          link
          color="secondary"
      >
        <v-list-item-icon>
          <v-icon>mdi-file-word-box</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Convert Documents</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
          :to="{ name: 'faq' }"
          link
          color="secondary"
      >
        <v-list-item-icon>
          <v-icon>mdi-help-circle</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>FAQ</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-list v-if="manager" dense nav>
      <v-divider></v-divider>
      <v-subheader>Management</v-subheader>

      <v-list-item
          :to="{ name: 'dictionary' }"
          link
          color="secondary"
      >
        <v-list-item-icon>
          <v-icon>mdi-book-open-blank-variant</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Dictionary</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
          :to="{ name: 'review' }"
          link
          color="secondary"
      >
        <v-list-item-icon>
          <v-icon>mdi-clipboard-text</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Review</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-list v-if="administrator" dense nav>
      <v-divider></v-divider>
      <v-subheader>Administration</v-subheader>

      <v-list-item
          :to="{ name: 'permissions' }"
          link
          color="secondary"
      >
        <v-list-item-icon>
          <v-icon>mdi-lock</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Permissions</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
          :to="{ name: 'roles' }"
          link
          color="secondary"
      >
        <v-list-item-icon>
          <v-icon>mdi-security</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Roles</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
          :to="{ name: 'users' }"
          link
          color="secondary"
      >
        <v-list-item-icon>
          <v-icon>mdi-account-supervisor</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Users</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
          :to="{ name: 'analytics' }"
          link
          color="secondary"
      >
        <v-list-item-icon>
          <v-icon>mdi-google-analytics</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Analytics</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AppNavigation',
  props: {
    drawer: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    user() {
      return this.guard('nt:Base');
    },
    manager() {
      return this.guard('nt:ManageWords');
    },
    administrator() {
      return this.guard('nt:Administrator');
    },
    ...mapGetters(['guard', 'showNav'])
  }
}
</script>

<style scoped>

</style>