var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"settings-developer"}},[_c('PageHeader',{attrs:{"title":"Developer Settings"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.editToken(_vm.defaultModel())}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-plus")]),_vm._v(" Generate Token ")],1)],1),_vm._m(0),_vm._m(1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tokens,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"footer-props":{ 'items-per-page-options': [5, 10, 25, 50, 100] },"sort-by":"created","sort-desc":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created))+" ")]}},{key:"item.regenerate",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.regenerate(item)}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"warning"},on:{"click":function($event){return _vm.editToken(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.deleteToken(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])}),_c('TokenEditDialog',{ref:"tokenEditDialog",attrs:{"token":_vm.token},on:{"save":_vm.refresh}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"mb-2"},[_vm._v("See our "),_c('a',{attrs:{"target":"_blank","href":"https://api.noahtext.com/api-docs/"}},[_vm._v("API documentation")]),_vm._v(" for information about converting text to Noah Text. HTML conversions and raw conversions are currently supported.")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v("When generating a token, set the "),_c('code',[_vm._v("Authorization")]),_vm._v(" header to the case-sensitive value "),_c('code',[_vm._v("Token {your token}")]),_vm._v(" to authenticate API calls. The token does not expire, and it has the same permissions as your account. If your token is compromised, regenerate it from this page and update usages as soon as possible.")])}]

export { render, staticRenderFns }