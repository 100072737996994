<template>
  <div id="privacy-policy">
    The documents and words uploaded into the StrongReader™ tool will not be viewed by Noah Text, LLC. However, Noah Text, LLC may analyze users' actions for the purposes of refining and improving the StrongReader™ product.  We will not use the email you provide, except in order to contact you to obtain feedback and/or provide notices, updates, and other relevant information regarding Noah Text® products.
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy'
}
</script>

<style scoped>
#privacy-policy {
  padding: 16px;
}
</style>
