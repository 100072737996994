<template>
  <Dialog ref="dialog" :title="result.question">
    <div v-html="result.answer"></div>
  </Dialog>
</template>

<script>
import Dialog from '@/components/general/Dialog';

export default {
  name: 'FAQResultViewDialog',
  components: {Dialog},
  props: {
    result: {
      type: Object,
      required: true
    }
  },
  methods: {
    open() {
      this.$refs.dialog.open();
    },
    close() {
      this.$refs.dialog.close();
    }
  }
}
</script>

<style scoped>

</style>