var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"roles"}},[_c('AppContent',[_c('PageHeader',{attrs:{"title":"Users"}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.users,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"footer-props":{ 'items-per-page-options': [5, 10, 25, 50, 100] },"sort-by":"created","sort-desc":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created))+" ")]}},{key:"item.updated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.updated))+" ")]}},{key:"item.roles",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.roles.map(function (roleId) { return _vm.roleName(roleId); }).join(', '))+" ")]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"warning"},on:{"click":function($event){return _vm.editUser(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.deleteUser(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])}),_c('UserEditDialog',{ref:"userEditDialog",attrs:{"user":_vm.user,"roles":_vm.roles},on:{"save":function($event){return _vm.refresh()}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }