<template>
  <v-app-bar
      app
      dark
      color="primary"
      clipped-left
  >
    <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.smAndDown && showNav"
        @click.stop="$emit('update:drawer', !drawer)"
    ></v-app-bar-nav-icon>

    <img v-if="$vuetify.breakpoint.mdAndUp" class="logo" alt="Logo" src="@/assets/logo.png" />
    <v-toolbar-title>
      <router-link :to="{ name: 'home' }">
        <div class="d-flex flex-column">
          <span class="title">StrongReader&trade; Builder</span>
          <span class="text-subtitle-2 white--text" style="margin-top: -8px">from Noah Text&reg;<span class="patent-number"> U.S. Patent No. 11,935,422</span></span>
        </div>
      </router-link>
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <v-btn
        v-if="!showNav"
        :to="{ name: 'login' }"
        text
        class="mr-2"
    >
      Log In
    </v-btn>

    <v-btn
        v-if="!showNav"
        :to="{ name: 'signup' }"
        color="secondary"
    >
      Sign Up
    </v-btn>

    <v-menu v-if="showNav" v-model="profileMenu">
      <template #activator="{ on }">
        <v-avatar role="button" v-on="on">
          <v-img src="@/assets/profile.png"></v-img>
        </v-avatar>
      </template>

      <ProfileDropdown @logout="logout"></ProfileDropdown>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex';
import { logout } from '@/common/util';
import ProfileDropdown from '@/components/users/ProfileDropdown';

export default {
  name: 'AppHeader',
  components: { ProfileDropdown },
  props: {
    drawer: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    profileMenu: false
  }),
  computed: mapGetters(['showNav']),
  methods: {
    async logout() {
      this.profileMenu = false;
      this.$nextTick(async () => await logout());
    }
  }
}
</script>

<style scoped>
.title {
  color: white;
}

.logo {
  max-height: 100%;
  padding-right: 8px;
  filter: drop-shadow(0 0 1px white);
}

.patent-number {
  font-weight: 400;
  font-size: 0.7rem;
  margin-left: 2px;
}

@media all and (max-width: 470px) {
  .patent-number {
    display: none;
  }
}
</style>
