<template>
  <Dialog ref="dialog" :title="`${role.id ? 'Edit' : 'New'} Role`">
    <v-form @submit.prevent="save" v-model="valid">
      <v-text-field
          autofocus
          label="Name"
          filled
          v-model="role.name"
          :rules="[v => !!v.trim() || 'Name is required']"
      ></v-text-field>

      <div class="d-flex align-center">
        <v-select
            :items="permissions"
            filled
            label="Permissions"
            item-text="name"
            item-value="id"
            v-model="permission"
            hide-details
        ></v-select>
        <v-btn class="ml-2" color="primary" @click="addPermission">
          <v-icon>mdi-plus</v-icon>
          Add
        </v-btn>
      </div>

      <v-list-item v-for="(permissionId, idx) in role.permissions" :key="idx">
        <v-list-item-title>{{permissionName(permissionId)}}</v-list-item-title>

        <v-list-item-action>
          <v-btn icon @click="role.permissions.splice(idx, 1)">
            <v-icon color="error">mdi-delete</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>

      <div v-if="role.permissions.length === 0" class="mt-4"></div>
    </v-form>

    <v-divider class="my-4"></v-divider>

    <div class="d-flex mt-2">
      <v-spacer></v-spacer>

      <v-btn text @click="$refs.dialog.close()">
        Cancel
      </v-btn>

      <v-btn
          text
          color="primary"
          :loading="saving"
          @click="save"
      >
        Save
      </v-btn>
    </div>
  </Dialog>
</template>

<script>
import { NoahTextAPI } from '@/common/noahtext-api';
import Dialog from '@/components/general/Dialog';

export default {
  name: "RoleEditDialog",
  components: { Dialog },
  props: {
    role: {
      type: Object,
      required: true
    },
    permissions: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    saving: false,
    valid: false,
    permission: null
  }),
  methods: {
    open() {
      this.permission = null;
      this.$refs.dialog.open();
    },
    close() {
      this.$refs.dialog.close();
    },
    permissionName(permissionId) {
      const results = this.permissions.filter((p) => p.id === permissionId);
      if (results.length > 0) {
        return results[0].name;
      } else {
        return 'Unknown';
      }
    },
    async save() {
      if (!this.valid) return;

      this.saving = true;
      const method = this.role.id ? NoahTextAPI.updateRole : NoahTextAPI.createRole;
      const updated = await method(this.role);
      this.$emit('save', updated);
      this.$refs.dialog.close();
      this.saving = false;
    },
    addPermission() {
      if (this.permission && !this.role.permissions.includes(this.permission)) {
        this.role.permissions.push(this.permission);
      }
    }
  }
}
</script>

<style scoped>

</style>
