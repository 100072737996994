export default {
  install(Vue) {
    let snackbar;
    Vue.prototype.$snackbar = {
      init(snackbarRef) {
        snackbar = snackbarRef;
      },
      open(message, color='info', timeout=4000) {
        snackbar.open(message, color, timeout);
      },
      success(message) {
        this.open(message, 'success');
      },
      error(message) {
        this.open(message, 'error', 0);
      },
      warning(message) {
        this.open(message, 'warning');
      }
    };
  }
};
